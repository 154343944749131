/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _breakpoints.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */

$breakpoints: (
	xxxs: (
		min: 340px,
		max: 339.98px,
	),
	xxs: (
		min: 380px,
		max: 379.98px,
	),
	xs: (
		min: 400px,
		max: 399.98px,
	),
	sm: (
		min: 576px,
		max: 575.98px,
	),
	md: (
		min: 830px,
		max: 829.98px,
	),
	lg: (
		min: 992px,
		max: 991.98px,
	),
	xl: (
		min: 1360px,
		max: 1359.98px,
	),
	xxl: (
		min: 1400px,
		max: 1399.98px,
	),
	xxxl: (
		min: 1640px,
		max: 1639.98px,
	),
);

@mixin breakpoint($breakpoint, $direction: max) {
	//check if the supplied breakpoint exists in our breakpoits map
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-values: map-get($breakpoints, $breakpoint);
		$breakpoint-min: map-get($breakpoint-values, min);
		$breakpoint-max: map-get($breakpoint-values, max);

		//check if we are writing styles for larger or smaller screens
		@if $direction == min {
			@media (min-width: $breakpoint-min) {
				@content;
			}
		} @else {
			@media (max-width: $breakpoint-max) {
				@content;
			}
		}

		// use the custom value if the breakpoint is not part of the pre-defined list
	} @else {
		@if $direction == min {
			@media (min-width: $breakpoint) {
				@content;
			}
		} @else {
			@media (max-width: $breakpoint) {
				@content;
			}
		}
	}
}
