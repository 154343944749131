/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _components.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
 [TABLE OF CONTENT]

 1. Navbar
-------------------------------------------------------------------------- */

@import "variables";
@import "breakpoints";
@import "mixin";

/* --------------------------------------------------------------------------
1. Navbar
 -------------------------------------------------------------------------- */
.navbar {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 10;
	background-color: transparent;
	padding: 44px 0;
	transition: 300ms;

	@include breakpoint(md) {
		padding: 20px 0;
	}

	&.scrolled {
		padding: 20px 0;
		transition: 300ms;
		background-color: $white;
		box-shadow: $box-shadow;

		@include breakpoint(md) {
			padding: 10px 0;
		}
	}

	&-nav {
		gap: 36px;
		align-items: center;

		@include breakpoint(xl) {
			gap: 18px;
		}
		@include breakpoint(md) {
			display: none;
		}

		.nav-link {
			font-weight: 600;
			font-size: 14px;
			line-height: 25px;
			letter-spacing: 0.095em;
			text-transform: uppercase;
			padding: 0;

			&[aria-current="page"] {
				outline: none;
				box-shadow: none;

				&:focus {
					outline: none;
					box-shadow: none;
				}
			}

			@include breakpoint(xxl) {
				font-size: 12px;
			}

			outline: none;
			box-shadow: none;

			&:focus {
				outline: none;
				box-shadow: none;
			}

			&-light {
				color: $white;
			}

			&-dark {
				color: $dark-blue;
			}
		}
	}
	.nav-item {
		outline: none;
		box-shadow: none;

		.btn {
			font-weight: 600;
			font-size: 14px;
			line-height: 25px;

			@include breakpoint(xxl) {
				font-size: 12px;
			}
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}

		@include dot-style-navbar;

		&:not(:last-child) {
			&:hover {
				@include dot-style-navbar-active;
			}
		}

		&.active {
			@include dot-style-navbar-active;
		}
	}
}

.footer {
	background-color: $dark-100;
	position: relative;
	z-index: 1;

	h6 {
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.145em;
		text-transform: uppercase;
		color: $orange;
		margin-bottom: 0;
		font-weight: 400;

		@include breakpoint(md) {
			text-align: center;
		}

		~ {
			ul {
				margin-top: 20px;
			}
		}
	}

	&-top {
		padding: 102px 0 62px;
		border-bottom: 1px solid $dark-200;
		@include breakpoint(md) {
			padding: 80px 0 40px;
		}
	}
	&-bottom {
		padding: 60px 0 130px;

		@include breakpoint(xl) {
			padding: 52px 0 76px;
		}
		@include breakpoint(md) {
			padding: 75px 0 85px;
		}

		p {
			font-weight: 400;
			font-size: 12px;
			line-height: 24px;
			letter-spacing: 0.015em;
			color: $dark-300;
			margin-top: 12px;
			max-width: 920px;
			margin-bottom: 0;

			@include breakpoint(xl) {
				max-width: fit-content;
			}
			@include breakpoint(md) {
				text-align: center;
			}
		}
		.list {
			@include breakpoint(xl) {
				margin-top: 36px;
			}
			@include breakpoint(md) {
				justify-content: center;
			}
			a {
				font-weight: 500;
				font-size: 14px;
				line-height: 27px;
				letter-spacing: 0.015em;
				color: $white;
			}
		}
	}
	.back-to-top {
		@include breakpoint(md) {
			transform: translateY(70px);
		}
	}
}

.back-to-top {
	&:hover {
		cursor: pointer;
	}
}

.offcanvas {
	&-menu {
		display: flex;
		height: 100vh;

		&-left {
			width: 240px;
			border-right: 1px solid $gray;
			position: relative;

			@include breakpoint(md) {
				display: none;
			}
		}

		&-right {
			flex: 1;
			align-self: self-end;
			overflow-y: auto;
			overflow-x: hidden;
			height: 100%;

			@include breakpoint(md) {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}

		&-vertical {
			flex-direction: row;
		}
	}
	&-nav {
		margin-top: 110px;
		margin-left: 112px;
		margin-bottom: 80px;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		gap: 30px;

		@include breakpoint(lg) {
			margin-left: 50px;
		}

		@include breakpoint(sm) {
			margin-left: 36px;
		}

		.nav-item {
			position: relative;

			&::before {
				content: "";
				width: 16px;
				height: 16px;
				border-radius: 50px;
				position: absolute;
				top: 50%;
				transform: translateY(-25%);
				left: -30px;
				transition: 300ms;
				z-index: -1;

				@include breakpoint(sm) {
					width: 10px;
					height: 10px;
				}
			}

			&.active {
				.nav-link {
					transform: translateX(26px);
					transition: 300ms;
					color: $orange;
				}
				&::before {
					background-color: $dark-blue-100;
					left: 0;
					transition: 300ms;
				}
			}

			&:hover {
				.nav-link {
					transform: translateX(26px);
					transition: 300ms;
					color: $orange;
				}
				&::before {
					background-color: $dark-blue-100;
					left: 0;
					transition: 300ms;
				}
			}
		}

		.nav-link {
			color: $dark-blue-100;
			font-family: "Playfair Display", serif;
			line-height: 74px;
			font-size: 60px;
			letter-spacing: 0.005em;
			text-transform: capitalize;
			transition: 300ms;
			overflow: hidden;
			padding: 0;
			@include breakpoint(sm) {
				font-size: 38px;
				line-height: 46px;
			}
		}
	}

	&-close {
		position: absolute;
		top: 65px;
		right: 118px;
		cursor: pointer;

		@include breakpoint(md) {
			top: 42px;
			right: 24px;
		}
		@include breakpoint(xs) {
			top: 30px;
			right: 10px;
		}
	}

	&-footer {
		color: $gray-100;
		padding-top: 80px;
		padding-left: 112px;
		padding-bottom: 106px;
		border-top: 1px solid $gray;

		@include breakpoint(lg) {
			padding-left: 50px;
		}

		@include breakpoint(sm) {
			padding-top: 44px;
			padding-bottom: 41px;
			padding-left: 36px;
		}

		h6 {
			font-weight: bold;
			font-size: 16px;
			line-height: 32px;
			letter-spacing: 0.115em;
			color: $dark-blue-100;
			margin-bottom: 21px;
		}

		p {
			font-size: 14px;
			line-height: 30px;
			letter-spacing: 0.005em;
			color: $gray-100;
			max-width: 254px;
			margin-bottom: 0;
		}
	}
}

/* Material Input */

.material-input-item {
	position: relative;

	.material-input {
		font-size: 18px;
		height: 40px;
		display: block;
		width: 100%;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $gray-700;
		color: $dark;

		&.textarea {
			height: auto;
			resize: none;
		}

		&-bar {
			position: relative;
			display: block;
			width: 100%;
			&::before {
				left: 50%;
				@extend .bar-before-after;
			}
			&::after {
				right: 50%;
				@extend .bar-before-after;
			}
		}

		&-label {
			color: $dark;
			position: absolute;
			pointer-events: none;
			font-size: 18px;
			top: 0;
			line-height: 35px;
			font-weight: 500;
			letter-spacing: 0.01em;
			transition: 0.4s ease all;

			@include breakpoint(xl) {
				font-size: 14px;
				line-height: 27px;
			}
		}

		&:focus,
		&:valid {
			~ {
				.material-input-label {
					top: -26px;
					font-size: 12px;
					color: $dark-300;
				}
			}
		}

		&:focus {
			outline: none;
			~ {
				.material-input-bar {
					&:before {
						width: 50%;
					}
					&:after {
						width: 50%;
					}
				}
			}
		}
	}

	.bar-before-after {
		content: "";
		height: 1px;
		width: 0;
		bottom: 0;
		position: absolute;
		background: $dark;
		transition: 0.4s ease all;
	}
}

.btn-anim {
	overflow: hidden;
	background: none;
	color: #fff;
	display: block;
	position: relative;
	z-index: 2;
	transition: all 0.3s;
	&::after {
		transition: all 0.3s;
		height: 100%;
		left: -38%;
		top: 0;
		transform: skew(50deg);
		transition-duration: 0.6s;
		transform-origin: top left;
		width: 0;
	}
	&:hover {
		color: $white;
		&:after {
			height: 100%;
			width: 138%;
		}
	}
}

.btn-outline {
	border: 2px solid $orange-200;

	&-orange {
		color: $orange-200;
	}

	&::before,
	&::after {
		background: $orange-200;
		content: "";
		position: absolute;
		z-index: -1;
	}
}

.btn-solid {
	border: 2px solid $orange-200;

	&-orange {
		background-color: $orange-200;
		color: $white;
	}

	&:hover {
		border: 2px solid $orange-300;
	}

	&::before,
	&::after {
		background: $orange-300;
		content: "";
		position: absolute;
		z-index: -1;
	}
}

.btn {
	&-flat {
		// padding: 26px 64px;
		font-weight: 600;
		// font-size: 18px;
		line-height: 22px;
		text-align: center;
		letter-spacing: 0.045em;
		border-radius: 0;

		@include breakpoint(xl) {
			padding: 15px 36px;
			font-size: 14px;
		}

		&-orange {
			background-color: $orange-200;
			color: $white;
		}

		&-bottom-right {
			margin-left: auto;
			display: block;
		}
	}
}
.testing {
	display: flex;
	justify-content: center;
	align-items: center;
}

.screen-horizontal .option {
	gap: 30px;
}

.option {
	position: relative;
	z-index: 1;
	max-width: 1200px;
	// margin: auto;
	gap: 60px;

	@include breakpoint(lg) {
		gap: 20px;
	}
	@include breakpoint(sm) {
		gap: 0px;
	}

	&-item {
		transition: 200ms;
	}

	&-image {
		background-color: rgba($color: $white, $alpha: 0.5);
		border-radius: 16px;
		padding: 10px;
		transition-duration: 0.25s;

		@include breakpoint(lg) {
			margin: auto;
			padding: 6px;
		}

		@include breakpoint(xxxs) {
			margin: auto;
			padding: 4px;
		}

		img {
			border-radius: 16px;
			width: 320px;
			height: 214px;
			object-fit: cover;

			@include breakpoint(sm) {
				width: 240px;
				height: 160px;
			}

			@include breakpoint(xs) {
				width: 215px;
				height: 146px;
			}

			@include breakpoint(xxs) {
				width: 190px;
				height: 130px;
			}

			@include breakpoint(xxxs) {
				width: 160px;
				height: 108px;
			}
		}
	}
	&-title {
		font-weight: 600;
		font-size: 18px;
		line-height: 123.3%;
		text-align: center;
		letter-spacing: 0.14em;
		text-transform: uppercase;
		color: $white;
		margin-bottom: 24px;
		transition-duration: 0.25s;
		margin-top: 12px;

		@include breakpoint(lg) {
			font-size: 16px;
		}
		@include breakpoint(sm) {
			font-size: 14px;
		}
		@include breakpoint(xxs) {
			font-size: 12px;
		}
	}
}

.option-no-slider {
	display: flex;
	align-items: center;

	@include breakpoint(lg) {
		flex-direction: column;
	}
	@include breakpoint(xxs) {
		margin-bottom: 20px;
	}
}

.option-no-slider .option-item {
	transition-duration: 0.3s;
	transform: scale(1);

	.option-title {
		opacity: 0.5;
	}

	&:hover {
		transition-duration: 0.3s;
		transform: scale(1.05);

		.option-image {
			background-color: rgba($color: $white, $alpha: 1);
		}

		.option-title {
			opacity: 1;
		}
	}
}
