/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : style.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
 [TABLE OF CONTENT]
-------------------------------------------------------------------------- */

@import "variables";
@import "breakpoints";
@import "utilities";
@import "components";
@import "hamburger-menu";

/* --------------------------------------------------------------------------
1. Main Hero
 -------------------------------------------------------------------------- */
// * {
// 	border: 1px solid red;
// }

.main-hero {
	h1 {
		font-family: "Playfair Display", serif;
		font-size: 68px;
		line-height: 84px;
		letter-spacing: 0.005em;
		color: $white;
		position: relative;
		z-index: 2;
		font-weight: normal;
		text-align: center;
		margin-bottom: 20px;

		@include breakpoint(lg) {
			font-size: 42px;
			line-height: 52px;
		}
	}

	p {
		font-size: 16px;
		line-height: 32px;
		letter-spacing: 0.015em;
		color: $white;
		max-width: 740px;
		z-index: 2;
		position: relative;
		font-weight: normal;
		margin: auto;
		text-align: center;

		@include breakpoint(xl) {
			max-width: 572px;
		}

		@include breakpoint(sm) {
			font-size: 12px;
			line-height: 24px;
		}

		u {
			text-underline-offset: 0.3em;
		}
	}
	.btn-link {
		transform: translateY(30px);
		text-decoration: none;

		@include breakpoint(xl) {
			transform: translateY(0);
			margin-left: 120px;
			margin-top: 38px;
		}
		@include breakpoint(md) {
			margin-left: 60px;
		}
		@include breakpoint(sm) {
			font-size: 12px;
			margin-left: 0;
		}
	}
}

/* --------------------------------------------------------------------------
2. ESG
 -------------------------------------------------------------------------- */
.esg {
	&-hero {
		min-height: 992px;
		@include breakpoint(lg) {
			min-height: 710px;
		}
		@include breakpoint(md) {
			min-height: 660px;
		}
		@include breakpoint(sm) {
			min-height: 670px;
		}

		&-content {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 90px;
			width: 100%;

			@include breakpoint(sm) {
				margin-bottom: 45px;
			}
		}

		h1 {
			font-family: "Playfair Display", serif;
			font-size: 97px;
			line-height: 120px;
			letter-spacing: 0.005em;
			color: $white;
			margin-bottom: 0;
			font-weight: 400;

			+ {
				h6 {
					color: $white;
					font-size: 20px;
				}
			}

			@include breakpoint(lg) {
				font-size: 60px;
				line-height: 74px;
			}
			@include breakpoint(sm) {
				font-size: 46px;
				line-height: 57px;
			}
			@include breakpoint(xs) {
				font-size: 36px;
				line-height: 46px;
			}
		}
	}

	&-image {
		background-image: url("../media/images/esg-primary.png");
		background-size: cover;
		width: 82.8%;
		height: 510px;
		background-repeat: no-repeat;
		background-position: left;
		z-index: 1;
		position: relative;

		&-wrapper {
			position: relative;
			width: 100%;
			margin-bottom: -320px;
			@include breakpoint(sm) {
				margin-bottom: -200px;
			}
		}

		@include breakpoint(xl) {
			width: 83%;
		}

		@include breakpoint(lg) {
			width: 78%;
			height: 276px;
		}

		@include breakpoint(md) {
			width: 100%;
			background-position: center;
		}
		@include breakpoint(sm) {
			height: 380px;
		}

		&::after {
			content: "";
			position: absolute;
			top: -306px;
			right: -236px;
			min-width: 236px;
			min-height: 306px;
			background: $dark-blue-300;

			@include breakpoint(xl) {
				min-width: 187px;
				min-height: 201px;
				top: -201px;
				right: -187px;
			}
			@include breakpoint(md) {
				display: none;
			}
		}

		@include gradient-blue-ocean;
	}

	&-year {
		font-family: "Playfair Display", serif;
		font-weight: 600;
		font-size: 38px;
		letter-spacing: 0.07em;
		color: $dark;
		margin-bottom: 0;
		@include breakpoint(sm) {
			display: none;
		}
	}
}

.commitment {
	h2 {
		margin-bottom: 40px;
	}

	.section-content {
		max-width: 512px;

		@include breakpoint(md) {
			max-width: 100%;
		}
	}

	&-list {
		margin: 0;
		display: flex;
		gap: 52px;
		list-style-type: none;
		margin-top: 40px;
		padding: 0;

		@include breakpoint(lg) {
			gap: 60px;
		}

		@include breakpoint(lg) {
			gap: 36px;
		}

		@include breakpoint(sm) {
			gap: 12px;
			flex-direction: column;
		}

		h4 {
			font-weight: bold;
			font-size: 28px;
			letter-spacing: 0.01em;
			color: $dark;
			margin-bottom: 0;

			@include breakpoint(xl) {
				font-size: 24px;
			}
			@include breakpoint(lg) {
				font-size: 18px;
			}
		}
	}

	p {
		font-size: 16px;
		line-height: 30px;
		letter-spacing: 0.015em;
		color: $dark;
		margin-bottom: 0;

		@include breakpoint(xl) {
			font-size: 14px;
			line-height: 27px;
		}

		@include breakpoint(xs) {
			font-size: 12px;
			line-height: 24px;
		}

		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}
}
/* --------------------------------------------------------------------------
3. Goal
 -------------------------------------------------------------------------- */

.goal {
	background-color: $white;
	&-title {
		font-family: "Playfair Display", serif;
		font-size: 42px;
		line-height: 68px;
		letter-spacing: 0.015em;
		color: $dark;
		font-weight: 400;
		margin-bottom: 0;

		@include breakpoint(lg) {
			font-size: 45px;
			line-height: 73px;
		}

		@include breakpoint(xs) {
			font-size: 28px;
			line-height: 45px;
			margin-bottom: 0;
		}
	}
	&-line {
		width: 100%;
		height: 7px;
		background-color: $gray-400;
	}
	&-subtitle {
		display: flex;
		align-items: baseline;
		white-space: nowrap;
		gap: 40px;

		p {
			font-size: 18px;
			line-height: 35px;
			letter-spacing: 0.015em;
			color: $dark;
			margin-bottom: 0;

			@include breakpoint(sm) {
				font-size: 16px;
			}
		}

		@include breakpoint(sm) {
			flex-direction: column-reverse;
			gap: 16px;
			padding-right: 0;
		}
	}

	&-grid {
		padding: 60px 0 160px;
		display: grid;
		grid-template-columns: repeat(7, 1fr);

		@include breakpoint(lg) {
			grid-template-columns: repeat(6, 1fr);
			padding: 30px 0 100px;
		}
		@include breakpoint(md) {
			grid-template-columns: repeat(4, 1fr);
		}
		@include breakpoint(sm) {
			grid-template-columns: repeat(3, 1fr);
		}

		img {
			width: 100%;
		}
	}
}

/* --------------------------------------------------------------------------
4. Communities
 -------------------------------------------------------------------------- */

.communities {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		height: 7px;
		left: 30px;
		right: 30px;
		bottom: 80px;

		@include breakpoint(xl) {
			background-color: $gray-400;
		}

		@include breakpoint(sm) {
			left: 15px;
			right: 15px;
		}
	}

	.line {
		background-color: $gray-200;
		height: 4px;
		width: 100%;
		display: block;
		margin: -10px 0 80px;
		z-index: 1;
		position: relative;
	}

	.section-subtitle {
		max-width: 536px;
	}

	p {
		width: 386px;

		@include breakpoint(md) {
			width: auto;
		}
	}

	.timeline-grid-lines {
		&:nth-child(2) {
			&::after {
				position: absolute;
				content: "";
				background-image: url("../media/svg/building.svg");
				width: 80px;
				height: 80px;
				left: 47.2%;
				top: -6.5%;

				@include breakpoint(xl) {
					display: none;
				}
			}
		}
	}

	.building-icon {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			background-color: $gray-600;
			height: 1px;
			top: 33.6%;
			left: 6px;
			right: 21px;

			@include breakpoint(xxxl) {
				left: 24px;
			}

			@include breakpoint(xxl) {
				left: 12px;
				top: 26.2%;
			}
			@include breakpoint(xl) {
				display: none;
			}
		}
	}
}

.milestone {
	.section-title {
		padding-right: 10px;
	}

	.section-subtitle {
		max-width: 586px;
	}

	p {
		width: 440px;

		@include breakpoint(md) {
			width: auto;
		}
	}

	.timeline-grid-lines {
		&:nth-child(2) {
			&::after {
				position: absolute;
				content: "";
				background-image: url("../media/svg/home.svg");
				width: 80px;
				height: 80px;
				left: 47.2%;
				top: -6.5%;

				@include breakpoint(xl) {
					display: none;
				}
			}
		}
	}

	.building-icon {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			background-color: $gray-600;
			height: 1px;
			top: 36.2%;
			left: 6px;
			right: 21px;

			@include breakpoint(xxxl) {
				left: 24px;
			}

			@include breakpoint(xxl) {
				left: 16px;
				top: 32.6%;
			}

			@include breakpoint(xl) {
				display: none;
			}
		}

		&::after {
			position: absolute;
			content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%3E%3Crect%20x%3D%226.36377%22%20width%3D%229%22%20height%3D%229%22%20transform%3D%22rotate(45%206.36377%200)%22%20fill%3D%22%230F3653%22%2F%3E%3C%2Fsvg%3E");
			z-index: 1;
			left: 50%;
			transform: translateX(-50%);
			top: 34.8%;

			@include breakpoint(xxl) {
				top: 31.3%;
			}

			@include breakpoint(xl) {
				display: none;
			}
		}
	}

	.grid-lines-center {
		padding-bottom: 60px;
	}
}

.timeline {
	background-color: $white;
	padding-bottom: 152px;

	@include breakpoint(xs) {
		padding-bottom: 90px;
	}

	&-title {
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.015em;
		max-width: 468px;
		text-align: center;
		margin: auto;
		margin-bottom: 26px;
	}

	&-bottom-center {
		transform: translateY(-32px);

		@include breakpoint(xl) {
			transform: none;
		}
	}

	&.communities {
		@include breakpoint(xs) {
			padding-bottom: 150px;
		}
	}

	.title-content {
		text-align: center;
		position: relative;

		@include breakpoint(xl) {
			display: flex;
			text-align: left;
			flex-direction: column;
			gap: 0;
			padding-left: 72px;
			padding-bottom: 42px;

			&::after {
				content: "";
				position: absolute;
				width: 1px;
				background-color: $gray-600;
				top: 5px;
				right: -24px;
				height: 100%;

				@include breakpoint(xl) {
					right: auto;
					left: -42px;
				}

				@include breakpoint(sm) {
					left: -30px;
				}

				@include breakpoint(xs) {
					left: -20px;
				}
			}
		}

		@include breakpoint(sm) {
			padding-left: 40px;
		}

		@include breakpoint(xs) {
			padding-left: 30px;
		}

		&:not(.title-content-center) {
			&::before {
				content: "";
				position: absolute;
				background-color: $gray-600;
				top: -33%;
				height: 33%;
				left: 49.8%;
				width: 0.5px;
				transform: translateX(-50%);

				@include breakpoint(xxl) {
					top: -40%;
					height: 40%;
				}

				@include breakpoint(xl) {
					display: none;
				}
			}
		}

		&-center {
			transform: translateY(110%);

			@include breakpoint(xl) {
				transform: translateY(0);
			}
		}
	}

	h6 {
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.015em;
		max-width: 472px;

		@include breakpoint(xs) {
			font-size: 18px;
			line-height: 28px;
		}
	}

	p {
		font-size: 16px;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.015em;
		margin: auto;
		margin-top: 24px;

		@include breakpoint(xl) {
			margin: 0;
			text-align: left;
		}

		@include breakpoint(sm) {
			font-size: 12px;
			line-height: 24px;
		}
	}

	&-title-content {
		position: relative;

		&::after {
			position: absolute;
			content: "";
			height: 6px;
			left: 12px;
			right: 12px;
			bottom: -40px;

			@include breakpoint(xl) {
				background-color: $gray-400;
			}

			@include breakpoint(xs) {
				bottom: -32px;
			}
		}
	}

	&-content {
		display: flex;
		align-items: flex-start;
	}

	&-grid {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		@include breakpoint(xl) {
			padding-left: 72px;
		}

		@include breakpoint(sm) {
			padding-left: 40px;
		}

		@include breakpoint(xs) {
			padding-left: 30px;
		}

		h3 {
			font-weight: 600;
			font-size: 16px;
			line-height: 23px;
			letter-spacing: 0.03em;
			color: $dark;
			margin-bottom: 10px;
		}

		i {
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.02em;
			color: $gray-500;
			display: block;
		}
	}

	.grid-lines-left {
		position: relative;

		&.grid-lines-center {
			h3 {
				@include breakpoint(xl, min) {
					&::before {
						display: none;
					}
				}
			}
		}

		&:not(:last-child) {
			&::after {
				content: "";
				position: absolute;
				width: 1px;
				background-color: $gray-600;
				top: 5px;
				right: -24px;
				height: 100%;

				@include breakpoint(xl) {
					right: auto;
					left: -114px;
				}

				@include breakpoint(sm) {
					left: -70px;
				}

				@include breakpoint(xs) {
					left: -50px;
				}
			}
		}

		&:last-child {
			@include breakpoint(xl) {
				padding-bottom: 60px;

				&::after {
					content: "";
					position: absolute;
					width: 1px;
					background-color: $gray-600;
					top: 5px;
					right: -24px;
					height: 100%;

					@include breakpoint(xl) {
						right: auto;
						left: -114px;
					}

					@include breakpoint(sm) {
						left: -70px;
					}

					@include breakpoint(xs) {
						left: -50px;
					}
				}
			}
			@include breakpoint(xs) {
				padding-bottom: 45px;
			}
		}

		h3 {
			position: relative;

			&.two-lines {
				&::after {
					top: 28%;
				}
			}

			&::before {
				position: absolute;
				content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%3E%3Crect%20x%3D%226.36377%22%20width%3D%229%22%20height%3D%229%22%20transform%3D%22rotate(45%206.36377%200)%22%20fill%3D%22%230F3653%22%2F%3E%3C%2Fsvg%3E");
				z-index: 1;
				right: -30px;
				top: 0;

				@include breakpoint(xl) {
					left: -120px;
					right: auto;
				}

				@include breakpoint(sm) {
					left: -76px;
				}

				@include breakpoint(xs) {
					left: -56px;
				}
			}
		}
	}
	.grid-lines-right {
		max-width: 450px;
		position: relative;

		&:not(:last-child) {
			&::before {
				content: "";
				position: absolute;
				width: 1px;
				background-color: $gray-600;
				top: 5px;
				/* bottom: 0; */
				left: -34px;
				height: 100%;

				@include breakpoint(xl) {
					right: auto;
					left: -114px;
				}

				@include breakpoint(sm) {
					left: -70px;
				}

				@include breakpoint(xs) {
					left: -50px;
				}
			}
		}

		h3 {
			position: relative;

			&::before {
				position: absolute;
				content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%3E%3Crect%20x%3D%226.36377%22%20width%3D%229%22%20height%3D%229%22%20transform%3D%22rotate(45%206.36377%200)%22%20fill%3D%22%230F3653%22%2F%3E%3C%2Fsvg%3E");
				z-index: 1;
				left: -40px;
				top: 0;

				@include breakpoint(xl) {
					left: -120px;
					right: auto;
				}

				@include breakpoint(sm) {
					left: -76px;
				}

				@include breakpoint(xs) {
					left: -56px;
				}
			}
		}
	}

	.grid-lines-center {
		max-width: 450px;

		@include breakpoint(xl) {
			margin-top: 0;
			text-align: left;
		}

		.timeline-grid-item {
			justify-content: center;

			@include breakpoint(xl) {
				justify-content: flex-start;
			}
		}
	}

	.grid-lines-item {
		width: 325px;

		@include breakpoint(xxl) {
			width: auto;
		}

		&:not(:last-child) {
			padding-bottom: 60px;

			@include breakpoint(xs) {
				padding-bottom: 45px;
			}
		}
	}

	&-grid-item {
		margin-top: 20px;
		display: flex;
		align-items: center;
		gap: 0;
		flex-wrap: wrap;

		@include breakpoint(xs) {
			margin-top: 16px;
		}

		img {
			width: 100px;

			@include breakpoint(sm) {
				width: 90px;
			}
		}
	}
}

/* --------------------------------------------------------------------------
5. Contact
 -------------------------------------------------------------------------- */
.contact-hero {
	height: 670px;
	display: flex;
	align-items: flex-end;
	padding-bottom: 80px;

	@include breakpoint(xl) {
		align-items: flex-start;
	}
	@include breakpoint(md) {
		height: 475px;
	}

	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&-desktop {
			@include breakpoint(md) {
				display: none;
			}
		}
	}

	&-bg {
		height: 1230px;
		min-width: 42%;
		background-image: url("../media/images/contact-us.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: left;
		z-index: 1;
		position: relative;
		padding: 74px 90px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&-content {
		position: relative;
		color: $white;
		z-index: 1;

		&-description {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			text-align: left;
			gap: 100px;

			@include breakpoint(xxxl) {
				gap: 50px;
			}

			@include breakpoint(xxl) {
				gap: 100px;
			}

			@include breakpoint(xl) {
				background-color: $white;
				color: $dark-600;
				padding: 60px 100px;
				position: relative;
				flex-direction: column;
				margin-top: -205px;

				&::after {
					content: "";
					position: absolute;
					height: 7px;
					left: 100px;
					right: 100px;
					top: 46%;
					background-color: $gray-400;

					@include breakpoint(md) {
						left: 46px;
						right: 46px;
						top: 32%;
					}
					@include breakpoint(sm) {
						left: 0;
						right: 0;
						top: 40%;
					}
					@include breakpoint(xs) {
						top: 44%;
					}
				}
			}

			@include breakpoint(md) {
				flex-direction: column;
				padding: 56px 35px;
				margin-top: -186px;
			}

			@include breakpoint(sm) {
				padding: 0;
				gap: 25px;
			}
			@include breakpoint(xs) {
			}
		}

		h2 {
			font-family: "Playfair Display", serif;
			font-size: 60px;
			line-height: 74px;
			letter-spacing: 0.005em;
			font-weight: 400;
			margin-bottom: 60px;
			text-align: center;

			@include breakpoint(xl) {
				margin-bottom: 0;
				margin-top: 235px;
				text-align: center;
			}
			@include breakpoint(md) {
				margin-top: 130px;
			}

			@include breakpoint(sm) {
				margin-top: 180px;
				font-size: 38px;
				line-height: 46px;
			}
		}

		h6 {
			font-weight: 600;
			font-size: 24px;
			letter-spacing: 0.015em;
			margin-bottom: 20px;

			@include breakpoint(sm) {
				font-size: 18px;
				line-height: 35px;
			}

			+ {
				p {
					line-height: 35px;
					letter-spacing: 0.055em;
					max-width: 340px;

					@include breakpoint(md) {
						margin: auto;
					}

					@include breakpoint(sm) {
						line-height: 24px;
						font-size: 12px;
					}
				}
			}
		}

		.investor-relations {
			padding: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			gap: 20px;
			margin-bottom: 0;
			flex-direction: column;

			@include breakpoint(xl) {
				flex-direction: row;
				justify-content: space-between;
			}

			@include breakpoint(md) {
				flex-direction: column;
				gap: 32px;
			}

			div {
				display: flex;
				flex-direction: column;
				gap: 10px;

				@include breakpoint(sm) {
					gap: 0;
				}

				p,
				span {
					font-size: 16px;
					letter-spacing: 0.015em;
					margin-bottom: 0;
					font-weight: normal;

					@include breakpoint(sm) {
						font-size: 12px;
					}
				}

				p {
					font-weight: 600;
				}

				span {
					display: block;
				}
			}
		}
	}
}

.contact-address {
	// @include breakpoint(xl) {

	// }

	@include breakpoint(md) {
		width: 100%;
		text-align: center;
	}

	@include breakpoint(sm) {
		padding: 56px 35px;
	}
}
.contact-detail {
	display: flex;
	gap: 135px;

	&-title {
		font-weight: 600;
		font-size: 18px;
		letter-spacing: 0.015em;
		color: $white;
		position: relative;
		margin-bottom: 0;

		&::after {
			position: absolute;
			content: "";
			width: 90px;
			height: 1px;
			top: 10px;
			right: -110px;
			background-color: $white;

			@include breakpoint(xl) {
				display: none;
			}
		}

		@include breakpoint(xl) {
			color: $dark-600;
		}
	}

	@include breakpoint(xl) {
		flex-grow: 1;
		width: 100%;
		flex-direction: column;
		gap: 20px;
	}

	@include breakpoint(md) {
		text-align: center;
	}
}

/* --------------------------------------------------------------------------
6. Investor
 -------------------------------------------------------------------------- */
.investor {
	margin: 60px 0 150px;

	@include breakpoint(xl) {
		padding: 0 100px;
		margin: 0 0 100px;
	}

	@include breakpoint(md) {
		margin-bottom: 0;
		padding: 56px 46px;
	}

	@include breakpoint(sm) {
		padding: 56px 46px;
	}

	&-title {
		font-size: 45px;
		line-height: 90px;
		text-align: center;
		letter-spacing: 0.015em;
		color: $dark-400;
		font-family: "Playfair Display", serif;
		margin-bottom: 56px;
		font-weight: 400;

		@include breakpoint(xl) {
			font-size: 42px;
			line-height: 82px;
			text-align: left;
		}

		@include breakpoint(md) {
			font-size: 28px;
			line-height: 55px;
			margin-bottom: 32px;
			text-align: center;
		}
	}
	&-item {
		text-align: center;

		@include breakpoint(xl) {
			text-align: left;
		}

		@include breakpoint(md) {
			margin-bottom: 32px;
			text-align: center;
		}

		p {
			font-weight: 600;
			font-size: 18px;
			line-height: 40px;
			letter-spacing: 0.015em;
			color: $orange;
			margin-bottom: 22px;

			@include breakpoint(lg) {
				font-size: 16px;
				line-height: 35px;
			}
		}

		img {
			display: block;
			margin: auto;

			@include breakpoint(xl) {
				margin-left: 0;
			}

			@include breakpoint(md) {
				margin-left: auto;
			}
			@include breakpoint(sm) {
				width: 70px;
			}
		}
		a {
			font-weight: normal;
			font-size: 18px;
			line-height: 40px;
			text-align: center;
			letter-spacing: 0.015em;
			color: $dark-400;
			margin-top: 10px;
			display: block;

			@include breakpoint(xl) {
				text-align: left;
			}

			@include breakpoint(lg) {
				font-size: 16px;
				line-height: 35px;
			}

			@include breakpoint(md) {
				text-align: center;
			}
		}
		span {
			font-weight: 600;
			font-size: 18px;
			line-height: 40px;
			text-align: center;
			letter-spacing: 0.015em;
			color: $dark-400;

			@include breakpoint(lg) {
				font-size: 16px;
				line-height: 35px;
			}
		}
	}
}
/* --------------------------------------------------------------------------
7. Universal Hero
 -------------------------------------------------------------------------- */
.universal-hero {
	height: 670px;
	display: flex;
	text-align: center;
	align-items: flex-start;

	@include breakpoint(lg) {
		height: 475px;
	}

	&-content {
		position: relative;
		color: $white;
		z-index: 1;

		h1 {
			font-family: "Playfair Display", serif;
			font-size: 60px;
			line-height: 74px;
			letter-spacing: 0.005em;
			font-weight: 400;
			margin-top: 225px;

			@include breakpoint(xl) {
				font-size: 68px;
				line-height: 84px;
			}

			@include breakpoint(lg) {
				font-size: 42px;
				line-height: 52px;
				margin-top: 186px;
			}

			@include breakpoint(sm) {
				font-size: 38px;
				line-height: 46px;
			}
		}
	}
}

/* --------------------------------------------------------------------------
4. Leadership
 -------------------------------------------------------------------------- */
.one-page-explanation {
	.content-togglable {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 37px;

		@include breakpoint(lg) {
			gap: 27px;
		}
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		gap: 72px;

		@include breakpoint(lg) {
			gap: 90px;
		}

		@include breakpoint(sm) {
			gap: 60px;
		}
	}

	&-item {
		position: relative;

		&::before {
			position: absolute;
			content: "";
			background-color: $gray-200;
			height: 4px;
			left: 0;
			right: 0;
			top: -46px;

			@include breakpoint(lg) {
				top: -35px;
			}

			@include breakpoint(sm) {
				top: -26px;
			}
		}
		.btn-circle-toggle {
			margin-top: 10px;
		}
	}

	&-content {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		gap: 130px;

		@include breakpoint(xl) {
			gap: 85px;
		}

		&-togglable {
			column-gap: 45px;

			p {
				font-size: 14px;
				line-height: 27px;
				text-align: left;
				letter-spacing: 0.015em;

				@include breakpoint(lg) {
					font-size: 12px;
					line-height: 24px;
				}
			}
		}
	}
	&-name {
		font-family: "Playfair Display", serif;
		font-size: 26px;
		line-height: 32px;
		letter-spacing: 0.005em;
	}

	&-position {
		p {
			font-weight: 500;
			letter-spacing: 0.13em;
			margin-bottom: 0;
			flex-shrink: 0;
			color: $orange;
			font-size: 10px;
			line-height: 20px;
		}
	}
	&-general {
		font-family: "Playfair Display", serif;
		font-size: 20px;
		line-height: 35px;
		letter-spacing: 0.015em;
		margin-bottom: 12px;
		font-weight: 400;

		+ {
			button {
				margin-right: 16px;
			}
		}

		@include breakpoint(xl) {
			font-size: 22px;
			line-height: 38px;
		}

		@include breakpoint(md) {
			font-size: 16px;
			line-height: 28px;
			margin-top: 12px;
		}

		@include breakpoint(sm) {
			max-width: 330px;
		}

		@include breakpoint(xs) {
			font-size: 12px;
			line-height: 21px;
		}
	}
}

/* Not found page */
.not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	text-align: center;

	h2 {
		font-family: "Playfair Display", serif;
	}
	ul {
		padding: 0;
		margin-top: 24px;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		gap: 16px;

		a {
			text-transform: capitalize;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: -5px;
				height: 1px;
				width: 0;
				background-color: black;
				transition: 300ms;
			}

			&:hover {
				&::before {
					transition: 300ms;
					width: 100%;
				}
			}
		}
	}
}

/* --------------------------------------------------------------------------
4. Option Selection Slider
 -------------------------------------------------------------------------- */
.bg-warehouse {
	position: relative;
	&::after {
		content: "";
		position: fixed;
		inset: 0;
		background-image: url("../media/images/background.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 0;
	}
}
.bg-blue-overlay {
	position: relative;
	&::after {
		content: "";
		position: fixed;
		inset: 0;
		background: linear-gradient(180deg, rgba(15, 54, 83, 0.75) 0%, rgba(15, 54, 83, 0.9) 100%);
		z-index: 1;
	}
}
