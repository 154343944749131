/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _mixin.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */

@mixin dot-style-navbar {
	position: relative;
	transition: 0.3s;
	&::after {
		content: "";
		position: absolute;
		width: 5px;
		height: 5px;
		bottom: -15px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		border-radius: 50px;
		transition: 0.3s;
	}
}
@mixin dot-style-navbar-active {
	&::after {
		background-color: $orange;
	}
}

@mixin hero-overlay {
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $dark-blue;
		opacity: 0.8;
		z-index: 0;
	}
}

@mixin gradient-blue-ocean {
	&::before {
		content: "";
		position: absolute;
		background: linear-gradient(180deg, #0f3653 0%, #075d9c 100%);
		mix-blend-mode: screen;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
@mixin card-slide-default {
	.option-image {
		background-color: rgba($color: $white, $alpha: 0.5);
		transform: scale(0.9);
		transition-duration: 0.25s;
	}
	.option-title {
		opacity: 0.5;
	}
}

@mixin card-slide-active {
	.option-image {
		background-color: rgba($color: $white, $alpha: 1);
		transition-duration: 0.25s;
	}
	.option-title {
		opacity: 1;
		transform: translateY(14px);
	}
	// .option-title {
	// }
}
