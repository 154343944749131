/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _utilities.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
@import "mixin";

body {
	font-family: "Montserrat", sans-serif;
	color: $dark;
}
a {
	color: $dark;
	text-decoration: none;
	&:hover {
		color: $dark;
	}
}
#logo {
	width: 252px;
	transition: width 300ms ease-in-out;

	@include breakpoint(xxl) {
		width: 200px;
	}

	@include breakpoint(xl) {
		width: 180px;
	}

	&.scrolled {
		width: 160px;
	}
}
.h-screen {
	height: 100vh;
}
.h-xl {
	min-height: 992px;

	@include breakpoint(md) {
		min-height: 660px;
	}

	@include breakpoint(sm) {
		min-height: 844px;
	}
}
.bg-color-hero {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $gradient;
		background-color: $dark;
		z-index: -2;
	}
}
.bg-image-building {
	position: relative;
	background-image: url("../media/images/building-glasses.png");
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;

	@include breakpoint(md) {
		background-position: bottom;
	}

	@include hero-overlay;
}

.bg-image-building-tower {
	position: relative;
	background-image: url("../media/images/bulding-tower.png");
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;

	@include breakpoint(md) {
		background-position: bottom;
	}

	@include hero-overlay;
}

.btn-xs {
	padding: 14px 45px;

	@include breakpoint(xxl) {
		padding: 12px 35px;
	}
}

.bg-image-people {
	position: relative;
	background-image: url("../media/images/people.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@include breakpoint(xs) {
		background-position: 28%;
	}

	@include hero-overlay;
}

.single-page {
	background: $white;
	z-index: 1;
	position: relative;
	max-width: 1154px;
	margin-left: auto;
	margin-right: auto;

	&:first-child {
		margin-top: -238px;
		padding: 90px 120px 0;

		@include breakpoint(xl) {
			padding: 90px 100px 0;
			margin-top: -145px;
		}

		@include breakpoint(md) {
			padding: 40px 40px 0;
		}
	}
	&:last-child {
		padding: 100px 120px 160px;

		@include breakpoint(xl) {
			padding: 100px 0;
		}

		@include breakpoint(sm) {
			padding: 70px 0 80px;
		}
	}

	&.gap-bottom {
		margin-bottom: 86px;
		@include breakpoint(xl) {
			margin-bottom: 0;
		}
		@include breakpoint(md) {
			margin-bottom: 45px;
		}
	}

	&.one-content {
		padding: 90px 120px;

		@include breakpoint(xl) {
			padding: 90px 100px 85px;
			margin-bottom: 0;
		}
		@include breakpoint(md) {
			padding: 35px;
			margin-bottom: 45px;
		}
	}
}

.single-description {
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.015em;
	color: $dark-600;
	margin-bottom: 16px;

	@include breakpoint(sm) {
		font-size: 12px;
		line-height: 24px;
	}
}

.grid-vertical {
	top: 162px;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: -1;

	@include breakpoint(xl) {
		display: none;
	}

	&::before {
		content: "";
		position: absolute;
		background-color: $border-vertical;
		height: 1px;
		top: 0;
		left: -20%;
		width: 100vw;
		right: -20%;
		margin: auto;
	}

	span {
		height: 100%;
		width: 1px;
		background-color: $border-vertical;
		&:first-child,
		&:last-child {
			@include breakpoint(md) {
				display: none;
			}
		}
	}
}

.bg-dark-blue {
	background-color: $dark-blue-200;
}

.icon-lines {
	@include breakpoint(xl) {
		padding-bottom: 115px;
	}
	@include breakpoint(sm) {
		padding-bottom: 145px;
	}
	@include breakpoint(xxs) {
		padding-bottom: 230px;
	}
}

.z-10 {
	z-index: 10;
}

.btn:focus {
	box-shadow: none;
	user-select: none;
}
.select-none {
	user-select: none;
}
.container-gap {
	@include breakpoint(xl) {
		padding: 0 50px;
	}
	@include breakpoint(lg) {
		padding: 0 30px;
	}
	@include breakpoint(sm) {
		padding: 0 15px;
	}
	&.carousel-gap {
		@include breakpoint(lg) {
			padding-right: 0;
			padding-left: 0;
		}
		@include breakpoint(sm) {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.fixed {
	position: fixed !important;
}

.btn {
	&:hover {
		color: $orange;
	}

	&-link {
		padding: 0;
		letter-spacing: 0.095em;
		text-transform: uppercase;
		color: $orange;
		font-weight: 600;
		font-size: 14px;
		line-height: 25px;

		&-icon {
			display: flex;
			align-items: center;
			gap: 25px;

			@include breakpoint(sm) {
				gap: 16px;
			}
			img {
				@include breakpoint(sm) {
					transform: scale(0.8);
				}
			}
		}
	}
}

.list {
	padding: 0;
	margin: 0;
	list-style-type: none;

	&-lg {
		gap: 42px;
	}
	&-offcanvas-footer {
		gap: 96px;
		font-size: 18px;

		@include breakpoint(lg) {
			font-size: 14px;
			gap: 50px;
		}
	}

	&-md-horizontal {
		@include breakpoint(md) {
			flex-direction: column;
			gap: 8px;
		}
	}

	&-horizontal {
		display: flex;
		align-items: center;
	}

	a {
		color: $white;
	}

	span {
		~ {
			p,
			strong {
				margin-top: 12px;
				display: block;
			}
		}
	}

	p,
	strong {
		display: block;

		&:first-child {
			margin-bottom: 12px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

strong {
	font-weight: 600;
}

.text-blend {
	font-weight: 600;
	color: $white;
	display: flex;
	align-items: center;
	gap: 10px;

	@include breakpoint(md) {
		flex-direction: column;
	}

	@include breakpoint(sm) {
		font-size: 12px;
	}

	a {
		font-weight: 400;
		font-size: 14px;
		line-height: 27px;
		letter-spacing: 0.025em;
		color: $white;
	}
}
@keyframes moveVertical {
	to {
		transform: translateY(-50%);
	}
}
@keyframes moveHorizontal {
	to {
		transform: translateX(-50%);
	}
}
.infinite {
	&-container {
		display: flex;
		flex-wrap: nowrap;
		gap: 20px;
	}
	&-vertical {
		animation: moveVertical 7s infinite linear;
	}
	&-horizontal {
		animation: moveHorizontal 7s infinite linear;
	}
}

.horizontal-infinite-text {
	white-space: nowrap;
	overflow: hidden;
}

.infinite-text {
	font-family: "Playfair Display", serif;
	font-size: 157px;
	font-weight: 700;
	color: $gray-200;
	white-space: nowrap;
	text-overflow: clip;
	margin: 0;
	margin-block: auto;

	&.infinite-text-vertical {
		writing-mode: vertical-lr;
		transform: rotate(180deg);
	}
}

.section-title {
	font-family: "Playfair Display", serif;
	font-size: 42px;
	line-height: 63px;
	letter-spacing: 0.015em;
	color: $dark;
	margin-bottom: 0;
	font-weight: 400;

	@include breakpoint(md) {
		font-size: 26px;
		line-height: 42px;
	}
}

.section-subtitle {
	font-size: 18px;
	line-height: 35px;
	letter-spacing: 0.015em;
	color: $dark;
	margin-bottom: 0;
	margin-left: auto;

	@include breakpoint(xl) {
		margin-right: auto;
		width: auto;
		margin-top: 16px;
		max-width: 574px;
	}
	@include breakpoint(lg) {
		font-size: 16px;
		line-height: 30px;
		letter-spacing: 0.015em;
	}
	@include breakpoint(md) {
		margin: 0;
		max-width: 100%;
		margin-top: 20px;
	}
	@include breakpoint(xs) {
		margin-top: 14px;
		padding-right: 16px;
		padding-left: 16px;
	}
}
.block-square {
	position: absolute;
	top: -306px;
	right: 0;
	width: 236px;
	height: 306px;
	background: $dark-blue-300;
	@include breakpoint(lg) {
		width: 187px;
		height: 201px;
	}
	@include breakpoint(md) {
		display: none;
	}
}

.icon-utils {
	width: 80px;
	height: 80px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	position: relative;

	@include breakpoint(xl) {
		position: sticky;
		top: 100px;
	}

	@include breakpoint(sm) {
		width: 60px;
		height: 60px;
	}

	@include breakpoint(xs) {
		width: 40px;
		height: 40px;
	}

	img {
		@include breakpoint(sm) {
			transform: scale(0.7);
		}
		@include breakpoint(xs) {
			transform: scale(0.5);
		}
	}

	&-gray {
		background-color: $gray-200;
	}
	&-light {
		background-color: $white;
		box-shadow: $icon-box-shadow;
	}
}

/* =============== */
.btn-circle-toggle {
	width: 40px;
	height: 40px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	border: 0;

	svg {
		transform: scale(0.8);
	}

	&:focus-visible {
		outline: 0;
	}

	&[aria-expanded="false"] {
		background-color: $orange;
		transform: rotate(0deg);
		transition: 500ms;
		svg {
			line {
				stroke: $white;
			}
		}
	}
	&[aria-expanded="true"] {
		background-color: $orange-100;
		transform: rotate(135deg);
		transition: 500ms;
		svg {
			line {
				stroke: $orange;
			}
		}
	}

	@include breakpoint(sm) {
		width: 30px;
		height: 30px;

		svg {
			transform: scale(0.6);
		}
	}
}

/* =============== */
.universal-explanation {
	margin: 230px 0 128px;

	@include breakpoint(lg) {
		margin: 120px 0 60px;
	}

	@include breakpoint(md) {
		margin: 200px 0 60px;
	}
	@include breakpoint(sm) {
		margin: 160px 0 60px;
	}
}

.universal-title {
	font-size: 46px;
	line-height: 80px;
	letter-spacing: 0.015em;
	font-family: "Playfair Display", serif;
	max-width: 1129px;
	margin-bottom: 30px;
	padding: 0 16px;
	font-weight: 400;

	@include breakpoint(lg) {
		font-size: 28px;
		line-height: 50px;
		letter-spacing: 0.015em;
		max-width: 540px;
	}
}
.universal-subtitle {
	font-size: 18px;
	line-height: 35px;
	text-align: justify;
	letter-spacing: 0.015em;
	max-width: 1156px;
	margin-left: 137px;
	padding: 0 16px;

	@include breakpoint(lg) {
		font-size: 16px;
		line-height: 30px;
		text-align: justify;
		letter-spacing: 0.015em;
		max-width: 572px;
		margin: auto;
	}
}

/* ================== */
@keyframes animate {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}
@keyframes animate2 {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-200%);
	}
}

.scroll {
	display: flex;
	position: absolute;
	bottom: -100px;
	left: 120px;
	transform: rotate(-90deg);
	transform-origin: left;
}

.scroll h2 {
	font-family: "Playfair Display", serif;
	font-size: 157px;
	font-weight: 500;
	color: $gray-200;
	white-space: nowrap;
	animation: animate 40s -40s linear infinite;
	will-change: transform;
}

.scroll h2:nth-child(2) {
	animation: animate2 40s -20s linear infinite;
}

.hero-on-top {
	@include breakpoint(sm) {
		display: none;
	}
}

.screen-vertical img.logo {
	position: absolute;
	top: 100px;
	margin-right: auto;
	margin-left: auto;
}

.slider-page img.logo {
	z-index: 2;
	left: 0;
	right: 0;

	@include breakpoint(xxl) {
		top: 50px;
	}

	@include breakpoint(lg) {
		position: inherit;
	}

	@include breakpoint(md) {
		width: 280px;
	}

	@include breakpoint(sm) {
		width: 260px;
	}

	@include breakpoint(xxs) {
		width: 220px;
	}
}

.screen-horizontal .additional-information {
	margin: 50px 0;
}

.screen-vertical .additional-information {
	position: absolute;
	bottom: 100px;
	margin-right: auto;
	margin-left: auto;
}

.slider-page .additional-information {
	z-index: 2;
	left: 0;
	right: 0;
	font-weight: 400;
	font-size: 21px;
	line-height: 160.3%;
	text-align: center;
	letter-spacing: 0.025em;
	padding: 0 20px;
	color: $white;

	@include breakpoint(xxl) {
		bottom: 50px;
	}

	@include breakpoint(lg) {
		font-size: 18px;
		position: inherit;
	}

	@include breakpoint(md) {
		font-size: 16px;
	}

	@include breakpoint(sm) {
		font-size: 14px;
		padding: 0 10px;
	}
	@include breakpoint(xxs) {
		font-size: 12px;
	}

	strong {
		color: $white;
	}
}
/* Heree all */
.h-screen-page {
	min-height: 100vh;
	@include breakpoint(lg) {
		min-height: 1200px;
	}
	@include breakpoint(md) {
		min-height: 400px;
	}
}
.main-section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	@include breakpoint(lg) {
		flex-direction: column;
		gap: 50px;
	}
}
