/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : style.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 [TABLE OF CONTENT]
-------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _variables.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _breakpoints.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _utilities.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _mixin.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
body {
  font-family: "Montserrat", sans-serif;
  color: #1f1f1f;
}

a {
  color: #1f1f1f;
  text-decoration: none;
}
a:hover {
  color: #1f1f1f;
}

#logo {
  width: 252px;
  transition: width 300ms ease-in-out;
}
@media (max-width: 1399.98px) {
  #logo {
    width: 200px;
  }
}
@media (max-width: 1359.98px) {
  #logo {
    width: 180px;
  }
}
#logo.scrolled {
  width: 160px;
}

.h-screen {
  height: 100vh;
}

.h-xl {
  min-height: 992px;
}
@media (max-width: 829.98px) {
  .h-xl {
    min-height: 660px;
  }
}
@media (max-width: 575.98px) {
  .h-xl {
    min-height: 844px;
  }
}

.bg-color-hero {
  position: relative;
}
.bg-color-hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(15, 54, 83, 0.75) 0%, rgba(15, 54, 83, 0.9) 100%);
  background-color: #1f1f1f;
  z-index: -2;
}

.bg-image-building {
  position: relative;
  background-image: url("../media/images/building-glasses.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
@media (max-width: 829.98px) {
  .bg-image-building {
    background-position: bottom;
  }
}
.bg-image-building::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0f3653;
  opacity: 0.8;
  z-index: 0;
}

.bg-image-building-tower {
  position: relative;
  background-image: url("../media/images/bulding-tower.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
@media (max-width: 829.98px) {
  .bg-image-building-tower {
    background-position: bottom;
  }
}
.bg-image-building-tower::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0f3653;
  opacity: 0.8;
  z-index: 0;
}

.btn-xs {
  padding: 14px 45px;
}
@media (max-width: 1399.98px) {
  .btn-xs {
    padding: 12px 35px;
  }
}

.bg-image-people {
  position: relative;
  background-image: url("../media/images/people.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 399.98px) {
  .bg-image-people {
    background-position: 28%;
  }
}
.bg-image-people::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0f3653;
  opacity: 0.8;
  z-index: 0;
}

.single-page {
  background: #fff;
  z-index: 1;
  position: relative;
  max-width: 1154px;
  margin-left: auto;
  margin-right: auto;
}
.single-page:first-child {
  margin-top: -238px;
  padding: 90px 120px 0;
}
@media (max-width: 1359.98px) {
  .single-page:first-child {
    padding: 90px 100px 0;
    margin-top: -145px;
  }
}
@media (max-width: 829.98px) {
  .single-page:first-child {
    padding: 40px 40px 0;
  }
}
.single-page:last-child {
  padding: 100px 120px 160px;
}
@media (max-width: 1359.98px) {
  .single-page:last-child {
    padding: 100px 0;
  }
}
@media (max-width: 575.98px) {
  .single-page:last-child {
    padding: 70px 0 80px;
  }
}
.single-page.gap-bottom {
  margin-bottom: 86px;
}
@media (max-width: 1359.98px) {
  .single-page.gap-bottom {
    margin-bottom: 0;
  }
}
@media (max-width: 829.98px) {
  .single-page.gap-bottom {
    margin-bottom: 45px;
  }
}
.single-page.one-content {
  padding: 90px 120px;
}
@media (max-width: 1359.98px) {
  .single-page.one-content {
    padding: 90px 100px 85px;
    margin-bottom: 0;
  }
}
@media (max-width: 829.98px) {
  .single-page.one-content {
    padding: 35px;
    margin-bottom: 45px;
  }
}

.single-description {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.015em;
  color: #383838;
  margin-bottom: 16px;
}
@media (max-width: 575.98px) {
  .single-description {
    font-size: 12px;
    line-height: 24px;
  }
}

.grid-vertical {
  top: 162px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: -1;
}
@media (max-width: 1359.98px) {
  .grid-vertical {
    display: none;
  }
}
.grid-vertical::before {
  content: "";
  position: absolute;
  background-color: rgba(169, 169, 169, 0.09);
  height: 1px;
  top: 0;
  left: -20%;
  width: 100vw;
  right: -20%;
  margin: auto;
}
.grid-vertical span {
  height: 100%;
  width: 1px;
  background-color: rgba(169, 169, 169, 0.09);
}
@media (max-width: 829.98px) {
  .grid-vertical span:first-child, .grid-vertical span:last-child {
    display: none;
  }
}

.bg-dark-blue {
  background-color: #122f45;
}

@media (max-width: 1359.98px) {
  .icon-lines {
    padding-bottom: 115px;
  }
}
@media (max-width: 575.98px) {
  .icon-lines {
    padding-bottom: 145px;
  }
}
@media (max-width: 379.98px) {
  .icon-lines {
    padding-bottom: 230px;
  }
}

.z-10 {
  z-index: 10;
}

.btn:focus {
  box-shadow: none;
  user-select: none;
}

.select-none {
  user-select: none;
}

@media (max-width: 1359.98px) {
  .container-gap {
    padding: 0 50px;
  }
}
@media (max-width: 991.98px) {
  .container-gap {
    padding: 0 30px;
  }
}
@media (max-width: 575.98px) {
  .container-gap {
    padding: 0 15px;
  }
}
@media (max-width: 991.98px) {
  .container-gap.carousel-gap {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 575.98px) {
  .container-gap.carousel-gap {
    padding-right: 0;
    padding-left: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fixed {
  position: fixed !important;
}

.btn:hover {
  color: #db812d;
}
.btn-link {
  padding: 0;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: #db812d;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}
.btn-link-icon {
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 575.98px) {
  .btn-link-icon {
    gap: 16px;
  }
}
@media (max-width: 575.98px) {
  .btn-link-icon img {
    transform: scale(0.8);
  }
}

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.list-lg {
  gap: 42px;
}
.list-offcanvas-footer {
  gap: 96px;
  font-size: 18px;
}
@media (max-width: 991.98px) {
  .list-offcanvas-footer {
    font-size: 14px;
    gap: 50px;
  }
}
@media (max-width: 829.98px) {
  .list-md-horizontal {
    flex-direction: column;
    gap: 8px;
  }
}
.list-horizontal {
  display: flex;
  align-items: center;
}
.list a {
  color: #fff;
}
.list span ~ p,
.list span ~ strong {
  margin-top: 12px;
  display: block;
}
.list p,
.list strong {
  display: block;
}
.list p:first-child,
.list strong:first-child {
  margin-bottom: 12px;
}
.list p:last-child,
.list strong:last-child {
  margin-bottom: 0;
}

strong {
  font-weight: 600;
}

.text-blend {
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 829.98px) {
  .text-blend {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .text-blend {
    font-size: 12px;
  }
}
.text-blend a {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.025em;
  color: #fff;
}

@keyframes moveVertical {
  to {
    transform: translateY(-50%);
  }
}
@keyframes moveHorizontal {
  to {
    transform: translateX(-50%);
  }
}
.infinite-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}
.infinite-vertical {
  animation: moveVertical 7s infinite linear;
}
.infinite-horizontal {
  animation: moveHorizontal 7s infinite linear;
}

.horizontal-infinite-text {
  white-space: nowrap;
  overflow: hidden;
}

.infinite-text {
  font-family: "Playfair Display", serif;
  font-size: 157px;
  font-weight: 700;
  color: #f8f8f8;
  white-space: nowrap;
  text-overflow: clip;
  margin: 0;
  margin-block: auto;
}
.infinite-text.infinite-text-vertical {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.section-title {
  font-family: "Playfair Display", serif;
  font-size: 42px;
  line-height: 63px;
  letter-spacing: 0.015em;
  color: #1f1f1f;
  margin-bottom: 0;
  font-weight: 400;
}
@media (max-width: 829.98px) {
  .section-title {
    font-size: 26px;
    line-height: 42px;
  }
}

.section-subtitle {
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.015em;
  color: #1f1f1f;
  margin-bottom: 0;
  margin-left: auto;
}
@media (max-width: 1359.98px) {
  .section-subtitle {
    margin-right: auto;
    width: auto;
    margin-top: 16px;
    max-width: 574px;
  }
}
@media (max-width: 991.98px) {
  .section-subtitle {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.015em;
  }
}
@media (max-width: 829.98px) {
  .section-subtitle {
    margin: 0;
    max-width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 399.98px) {
  .section-subtitle {
    margin-top: 14px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.block-square {
  position: absolute;
  top: -306px;
  right: 0;
  width: 236px;
  height: 306px;
  background: #174363;
}
@media (max-width: 991.98px) {
  .block-square {
    width: 187px;
    height: 201px;
  }
}
@media (max-width: 829.98px) {
  .block-square {
    display: none;
  }
}

.icon-utils {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
}
@media (max-width: 1359.98px) {
  .icon-utils {
    position: sticky;
    top: 100px;
  }
}
@media (max-width: 575.98px) {
  .icon-utils {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 399.98px) {
  .icon-utils {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 575.98px) {
  .icon-utils img {
    transform: scale(0.7);
  }
}
@media (max-width: 399.98px) {
  .icon-utils img {
    transform: scale(0.5);
  }
}
.icon-utils-gray {
  background-color: #f8f8f8;
}
.icon-utils-light {
  background-color: #fff;
  box-shadow: 0px 15px 21px 3px rgba(0, 0, 0, 0.05);
}

/* =============== */
.btn-circle-toggle {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border: 0;
}
.btn-circle-toggle svg {
  transform: scale(0.8);
}
.btn-circle-toggle:focus-visible {
  outline: 0;
}
.btn-circle-toggle[aria-expanded=false] {
  background-color: #db812d;
  transform: rotate(0deg);
  transition: 500ms;
}
.btn-circle-toggle[aria-expanded=false] svg line {
  stroke: #fff;
}
.btn-circle-toggle[aria-expanded=true] {
  background-color: #ffeede;
  transform: rotate(135deg);
  transition: 500ms;
}
.btn-circle-toggle[aria-expanded=true] svg line {
  stroke: #db812d;
}
@media (max-width: 575.98px) {
  .btn-circle-toggle {
    width: 30px;
    height: 30px;
  }
  .btn-circle-toggle svg {
    transform: scale(0.6);
  }
}

/* =============== */
.universal-explanation {
  margin: 230px 0 128px;
}
@media (max-width: 991.98px) {
  .universal-explanation {
    margin: 120px 0 60px;
  }
}
@media (max-width: 829.98px) {
  .universal-explanation {
    margin: 200px 0 60px;
  }
}
@media (max-width: 575.98px) {
  .universal-explanation {
    margin: 160px 0 60px;
  }
}

.universal-title {
  font-size: 46px;
  line-height: 80px;
  letter-spacing: 0.015em;
  font-family: "Playfair Display", serif;
  max-width: 1129px;
  margin-bottom: 30px;
  padding: 0 16px;
  font-weight: 400;
}
@media (max-width: 991.98px) {
  .universal-title {
    font-size: 28px;
    line-height: 50px;
    letter-spacing: 0.015em;
    max-width: 540px;
  }
}

.universal-subtitle {
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  letter-spacing: 0.015em;
  max-width: 1156px;
  margin-left: 137px;
  padding: 0 16px;
}
@media (max-width: 991.98px) {
  .universal-subtitle {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.015em;
    max-width: 572px;
    margin: auto;
  }
}

/* ================== */
@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
.scroll {
  display: flex;
  position: absolute;
  bottom: -100px;
  left: 120px;
  transform: rotate(-90deg);
  transform-origin: left;
}

.scroll h2 {
  font-family: "Playfair Display", serif;
  font-size: 157px;
  font-weight: 500;
  color: #f8f8f8;
  white-space: nowrap;
  animation: animate 40s -40s linear infinite;
  will-change: transform;
}

.scroll h2:nth-child(2) {
  animation: animate2 40s -20s linear infinite;
}

@media (max-width: 575.98px) {
  .hero-on-top {
    display: none;
  }
}

.screen-vertical img.logo {
  position: absolute;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
}

.slider-page img.logo {
  z-index: 2;
  left: 0;
  right: 0;
}
@media (max-width: 1399.98px) {
  .slider-page img.logo {
    top: 50px;
  }
}
@media (max-width: 991.98px) {
  .slider-page img.logo {
    position: inherit;
  }
}
@media (max-width: 829.98px) {
  .slider-page img.logo {
    width: 280px;
  }
}
@media (max-width: 575.98px) {
  .slider-page img.logo {
    width: 260px;
  }
}
@media (max-width: 379.98px) {
  .slider-page img.logo {
    width: 220px;
  }
}

.screen-horizontal .additional-information {
  margin: 50px 0;
}

.screen-vertical .additional-information {
  position: absolute;
  bottom: 100px;
  margin-right: auto;
  margin-left: auto;
}

.slider-page .additional-information {
  z-index: 2;
  left: 0;
  right: 0;
  font-weight: 400;
  font-size: 21px;
  line-height: 160.3%;
  text-align: center;
  letter-spacing: 0.025em;
  padding: 0 20px;
  color: #fff;
}
@media (max-width: 1399.98px) {
  .slider-page .additional-information {
    bottom: 50px;
  }
}
@media (max-width: 991.98px) {
  .slider-page .additional-information {
    font-size: 18px;
    position: inherit;
  }
}
@media (max-width: 829.98px) {
  .slider-page .additional-information {
    font-size: 16px;
  }
}
@media (max-width: 575.98px) {
  .slider-page .additional-information {
    font-size: 14px;
    padding: 0 10px;
  }
}
@media (max-width: 379.98px) {
  .slider-page .additional-information {
    font-size: 12px;
  }
}
.slider-page .additional-information strong {
  color: #fff;
}

/* Heree all */
.h-screen-page {
  min-height: 100vh;
}
@media (max-width: 991.98px) {
  .h-screen-page {
    min-height: 1200px;
  }
}
@media (max-width: 829.98px) {
  .h-screen-page {
    min-height: 400px;
  }
}

.main-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 991.98px) {
  .main-section {
    flex-direction: column;
    gap: 50px;
  }
}

/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _components.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 [TABLE OF CONTENT]

 1. Navbar
-------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _variables.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _breakpoints.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _mixin.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
1. Navbar
 -------------------------------------------------------------------------- */
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  background-color: transparent;
  padding: 44px 0;
  transition: 300ms;
}
@media (max-width: 829.98px) {
  .navbar {
    padding: 20px 0;
  }
}
.navbar.scrolled {
  padding: 20px 0;
  transition: 300ms;
  background-color: #fff;
  box-shadow: 0 -4px 15px #e8e8ec;
}
@media (max-width: 829.98px) {
  .navbar.scrolled {
    padding: 10px 0;
  }
}
.navbar-nav {
  gap: 36px;
  align-items: center;
}
@media (max-width: 1359.98px) {
  .navbar-nav {
    gap: 18px;
  }
}
@media (max-width: 829.98px) {
  .navbar-nav {
    display: none;
  }
}
.navbar-nav .nav-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  padding: 0;
  outline: none;
  box-shadow: none;
}
.navbar-nav .nav-link[aria-current=page] {
  outline: none;
  box-shadow: none;
}
.navbar-nav .nav-link[aria-current=page]:focus {
  outline: none;
  box-shadow: none;
}
@media (max-width: 1399.98px) {
  .navbar-nav .nav-link {
    font-size: 12px;
  }
}
.navbar-nav .nav-link:focus {
  outline: none;
  box-shadow: none;
}
.navbar-nav .nav-link-light {
  color: #fff;
}
.navbar-nav .nav-link-dark {
  color: #0f3653;
}
.navbar .nav-item {
  outline: none;
  box-shadow: none;
  position: relative;
  transition: 0.3s;
}
.navbar .nav-item .btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}
@media (max-width: 1399.98px) {
  .navbar .nav-item .btn {
    font-size: 12px;
  }
}
.navbar .nav-item:focus {
  outline: none;
  box-shadow: none;
}
.navbar .nav-item::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  bottom: -15px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  transition: 0.3s;
}
.navbar .nav-item:not(:last-child):hover::after {
  background-color: #db812d;
}
.navbar .nav-item.active::after {
  background-color: #db812d;
}

.footer {
  background-color: #0e2638;
  position: relative;
  z-index: 1;
}
.footer h6 {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.145em;
  text-transform: uppercase;
  color: #db812d;
  margin-bottom: 0;
  font-weight: 400;
}
@media (max-width: 829.98px) {
  .footer h6 {
    text-align: center;
  }
}
.footer h6 ~ ul {
  margin-top: 20px;
}
.footer-top {
  padding: 102px 0 62px;
  border-bottom: 1px solid #15354c;
}
@media (max-width: 829.98px) {
  .footer-top {
    padding: 80px 0 40px;
  }
}
.footer-bottom {
  padding: 60px 0 130px;
}
@media (max-width: 1359.98px) {
  .footer-bottom {
    padding: 52px 0 76px;
  }
}
@media (max-width: 829.98px) {
  .footer-bottom {
    padding: 75px 0 85px;
  }
}
.footer-bottom p {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #788f9f;
  margin-top: 12px;
  max-width: 920px;
  margin-bottom: 0;
}
@media (max-width: 1359.98px) {
  .footer-bottom p {
    max-width: fit-content;
  }
}
@media (max-width: 829.98px) {
  .footer-bottom p {
    text-align: center;
  }
}
@media (max-width: 1359.98px) {
  .footer-bottom .list {
    margin-top: 36px;
  }
}
@media (max-width: 829.98px) {
  .footer-bottom .list {
    justify-content: center;
  }
}
.footer-bottom .list a {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.015em;
  color: #fff;
}
@media (max-width: 829.98px) {
  .footer .back-to-top {
    transform: translateY(70px);
  }
}

.back-to-top:hover {
  cursor: pointer;
}

.offcanvas-menu {
  display: flex;
  height: 100vh;
}
.offcanvas-menu-left {
  width: 240px;
  border-right: 1px solid #ececec;
  position: relative;
}
@media (max-width: 829.98px) {
  .offcanvas-menu-left {
    display: none;
  }
}
.offcanvas-menu-right {
  flex: 1;
  align-self: self-end;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
@media (max-width: 829.98px) {
  .offcanvas-menu-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.offcanvas-menu-vertical {
  flex-direction: row;
}
.offcanvas-nav {
  margin-top: 110px;
  margin-left: 112px;
  margin-bottom: 80px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .offcanvas-nav {
    margin-left: 50px;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-nav {
    margin-left: 36px;
  }
}
.offcanvas-nav .nav-item {
  position: relative;
}
.offcanvas-nav .nav-item::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-25%);
  left: -30px;
  transition: 300ms;
  z-index: -1;
}
@media (max-width: 575.98px) {
  .offcanvas-nav .nav-item::before {
    width: 10px;
    height: 10px;
  }
}
.offcanvas-nav .nav-item.active .nav-link {
  transform: translateX(26px);
  transition: 300ms;
  color: #db812d;
}
.offcanvas-nav .nav-item.active::before {
  background-color: #12334b;
  left: 0;
  transition: 300ms;
}
.offcanvas-nav .nav-item:hover .nav-link {
  transform: translateX(26px);
  transition: 300ms;
  color: #db812d;
}
.offcanvas-nav .nav-item:hover::before {
  background-color: #12334b;
  left: 0;
  transition: 300ms;
}
.offcanvas-nav .nav-link {
  color: #12334b;
  font-family: "Playfair Display", serif;
  line-height: 74px;
  font-size: 60px;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  transition: 300ms;
  overflow: hidden;
  padding: 0;
}
@media (max-width: 575.98px) {
  .offcanvas-nav .nav-link {
    font-size: 38px;
    line-height: 46px;
  }
}
.offcanvas-close {
  position: absolute;
  top: 65px;
  right: 118px;
  cursor: pointer;
}
@media (max-width: 829.98px) {
  .offcanvas-close {
    top: 42px;
    right: 24px;
  }
}
@media (max-width: 399.98px) {
  .offcanvas-close {
    top: 30px;
    right: 10px;
  }
}
.offcanvas-footer {
  color: #75848e;
  padding-top: 80px;
  padding-left: 112px;
  padding-bottom: 106px;
  border-top: 1px solid #ececec;
}
@media (max-width: 991.98px) {
  .offcanvas-footer {
    padding-left: 50px;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-footer {
    padding-top: 44px;
    padding-bottom: 41px;
    padding-left: 36px;
  }
}
.offcanvas-footer h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.115em;
  color: #12334b;
  margin-bottom: 21px;
}
.offcanvas-footer p {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.005em;
  color: #75848e;
  max-width: 254px;
  margin-bottom: 0;
}

/* Material Input */
.material-input-item {
  position: relative;
}
.material-input-item .material-input {
  font-size: 18px;
  height: 40px;
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #dedede;
  color: #1f1f1f;
}
.material-input-item .material-input.textarea {
  height: auto;
  resize: none;
}
.material-input-item .material-input-bar {
  position: relative;
  display: block;
  width: 100%;
}
.material-input-item .material-input-bar::before {
  left: 50%;
}
.material-input-item .material-input-bar::after {
  right: 50%;
}
.material-input-item .material-input-label {
  color: #1f1f1f;
  position: absolute;
  pointer-events: none;
  font-size: 18px;
  top: 0;
  line-height: 35px;
  font-weight: 500;
  letter-spacing: 0.01em;
  transition: 0.4s ease all;
}
@media (max-width: 1359.98px) {
  .material-input-item .material-input-label {
    font-size: 14px;
    line-height: 27px;
  }
}
.material-input-item .material-input:focus ~ .material-input-label, .material-input-item .material-input:valid ~ .material-input-label {
  top: -26px;
  font-size: 12px;
  color: #788f9f;
}
.material-input-item .material-input:focus {
  outline: none;
}
.material-input-item .material-input:focus ~ .material-input-bar:before {
  width: 50%;
}
.material-input-item .material-input:focus ~ .material-input-bar:after {
  width: 50%;
}
.material-input-item .bar-before-after, .material-input-item .material-input-bar::before, .material-input-item .material-input-bar::after {
  content: "";
  height: 1px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #1f1f1f;
  transition: 0.4s ease all;
}

.btn-anim {
  overflow: hidden;
  background: none;
  color: #fff;
  display: block;
  position: relative;
  z-index: 2;
  transition: all 0.3s;
}
.btn-anim::after {
  transition: all 0.3s;
  height: 100%;
  left: -38%;
  top: 0;
  transform: skew(50deg);
  transition-duration: 0.6s;
  transform-origin: top left;
  width: 0;
}
.btn-anim:hover {
  color: #fff;
}
.btn-anim:hover:after {
  height: 100%;
  width: 138%;
}

.btn-outline {
  border: 2px solid #c06f24;
}
.btn-outline-orange {
  color: #c06f24;
}
.btn-outline::before, .btn-outline::after {
  background: #c06f24;
  content: "";
  position: absolute;
  z-index: -1;
}

.btn-solid {
  border: 2px solid #c06f24;
}
.btn-solid-orange {
  background-color: #c06f24;
  color: #fff;
}
.btn-solid:hover {
  border: 2px solid #db781b;
}
.btn-solid::before, .btn-solid::after {
  background: #db781b;
  content: "";
  position: absolute;
  z-index: -1;
}

.btn-flat {
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.045em;
  border-radius: 0;
}
@media (max-width: 1359.98px) {
  .btn-flat {
    padding: 15px 36px;
    font-size: 14px;
  }
}
.btn-flat-orange {
  background-color: #c06f24;
  color: #fff;
}
.btn-flat-bottom-right {
  margin-left: auto;
  display: block;
}

.testing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen-horizontal .option {
  gap: 30px;
}

.option {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  gap: 60px;
}
@media (max-width: 991.98px) {
  .option {
    gap: 20px;
  }
}
@media (max-width: 575.98px) {
  .option {
    gap: 0px;
  }
}
.option-item {
  transition: 200ms;
}
.option-image {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  padding: 10px;
  transition-duration: 0.25s;
}
@media (max-width: 991.98px) {
  .option-image {
    margin: auto;
    padding: 6px;
  }
}
@media (max-width: 339.98px) {
  .option-image {
    margin: auto;
    padding: 4px;
  }
}
.option-image img {
  border-radius: 16px;
  width: 320px;
  height: 214px;
  object-fit: cover;
}
@media (max-width: 575.98px) {
  .option-image img {
    width: 240px;
    height: 160px;
  }
}
@media (max-width: 399.98px) {
  .option-image img {
    width: 215px;
    height: 146px;
  }
}
@media (max-width: 379.98px) {
  .option-image img {
    width: 190px;
    height: 130px;
  }
}
@media (max-width: 339.98px) {
  .option-image img {
    width: 160px;
    height: 108px;
  }
}
.option-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 123.3%;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 24px;
  transition-duration: 0.25s;
  margin-top: 12px;
}
@media (max-width: 991.98px) {
  .option-title {
    font-size: 16px;
  }
}
@media (max-width: 575.98px) {
  .option-title {
    font-size: 14px;
  }
}
@media (max-width: 379.98px) {
  .option-title {
    font-size: 12px;
  }
}

.option-no-slider {
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .option-no-slider {
    flex-direction: column;
  }
}
@media (max-width: 379.98px) {
  .option-no-slider {
    margin-bottom: 20px;
  }
}

.option-no-slider .option-item {
  transition-duration: 0.3s;
  transform: scale(1);
}
.option-no-slider .option-item .option-title {
  opacity: 0.5;
}
.option-no-slider .option-item:hover {
  transition-duration: 0.3s;
  transform: scale(1.05);
}
.option-no-slider .option-item:hover .option-image {
  background-color: white;
}
.option-no-slider .option-item:hover .option-title {
  opacity: 1;
}

/* --------------------------------------------------------------------------
 * Name           : Shelter Growth Capital Partners
 * File           : _hamburger-menu.scss
 * Version        : 1.0.0
 * Author         : Dzaki Fadhlurrohman
 * Author URI     : https://dzakifadh.vercel.app/
 *
 * Dzakifadh. Copyright 2022. All Rights Reserved.
 * -------------------------------------------------------------------------- */
.hamburger-container {
  width: 25px;
  height: 18px;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.2s;
}
.hamburger-container span {
  display: block;
  position: absolute;
  height: 2px;
  transform: rotate(0deg);
  transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
  opacity: 1;
  width: 100%;
  border-radius: 50px;
  left: 0;
}

.hamburger-dark {
  background-color: #1f1f1f;
}
.hamburger-light {
  background-color: #fff;
}
.hamburger span:nth-child(1) {
  top: 0px;
}
.hamburger span:nth-child(2), .hamburger span:nth-child(3) {
  top: 8px;
}
.hamburger span:nth-child(4) {
  top: 16px;
}

label[aria-expanded=true] > .hamburger-container > span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
label[aria-expanded=true] > .hamburger-container > span:nth-child(2) {
  transform: rotate(45deg);
}
label[aria-expanded=true] > .hamburger-container > span:nth-child(3) {
  transform: rotate(-45deg);
}
label[aria-expanded=true] > .hamburger-container > span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* --------------------------------------------------------------------------
1. Main Hero
 -------------------------------------------------------------------------- */
.main-hero h1 {
  font-family: "Playfair Display", serif;
  font-size: 68px;
  line-height: 84px;
  letter-spacing: 0.005em;
  color: #fff;
  position: relative;
  z-index: 2;
  font-weight: normal;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .main-hero h1 {
    font-size: 42px;
    line-height: 52px;
  }
}
.main-hero p {
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.015em;
  color: #fff;
  max-width: 740px;
  z-index: 2;
  position: relative;
  font-weight: normal;
  margin: auto;
  text-align: center;
}
@media (max-width: 1359.98px) {
  .main-hero p {
    max-width: 572px;
  }
}
@media (max-width: 575.98px) {
  .main-hero p {
    font-size: 12px;
    line-height: 24px;
  }
}
.main-hero p u {
  text-underline-offset: 0.3em;
}
.main-hero .btn-link {
  transform: translateY(30px);
  text-decoration: none;
}
@media (max-width: 1359.98px) {
  .main-hero .btn-link {
    transform: translateY(0);
    margin-left: 120px;
    margin-top: 38px;
  }
}
@media (max-width: 829.98px) {
  .main-hero .btn-link {
    margin-left: 60px;
  }
}
@media (max-width: 575.98px) {
  .main-hero .btn-link {
    font-size: 12px;
    margin-left: 0;
  }
}

/* --------------------------------------------------------------------------
2. ESG
 -------------------------------------------------------------------------- */
.esg-hero {
  min-height: 992px;
}
@media (max-width: 991.98px) {
  .esg-hero {
    min-height: 710px;
  }
}
@media (max-width: 829.98px) {
  .esg-hero {
    min-height: 660px;
  }
}
@media (max-width: 575.98px) {
  .esg-hero {
    min-height: 670px;
  }
}
.esg-hero-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 90px;
  width: 100%;
}
@media (max-width: 575.98px) {
  .esg-hero-content {
    margin-bottom: 45px;
  }
}
.esg-hero h1 {
  font-family: "Playfair Display", serif;
  font-size: 97px;
  line-height: 120px;
  letter-spacing: 0.005em;
  color: #fff;
  margin-bottom: 0;
  font-weight: 400;
}
.esg-hero h1 + h6 {
  color: #fff;
  font-size: 20px;
}
@media (max-width: 991.98px) {
  .esg-hero h1 {
    font-size: 60px;
    line-height: 74px;
  }
}
@media (max-width: 575.98px) {
  .esg-hero h1 {
    font-size: 46px;
    line-height: 57px;
  }
}
@media (max-width: 399.98px) {
  .esg-hero h1 {
    font-size: 36px;
    line-height: 46px;
  }
}
.esg-image {
  background-image: url("../media/images/esg-primary.png");
  background-size: cover;
  width: 82.8%;
  height: 510px;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 1;
  position: relative;
}
.esg-image-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: -320px;
}
@media (max-width: 575.98px) {
  .esg-image-wrapper {
    margin-bottom: -200px;
  }
}
@media (max-width: 1359.98px) {
  .esg-image {
    width: 83%;
  }
}
@media (max-width: 991.98px) {
  .esg-image {
    width: 78%;
    height: 276px;
  }
}
@media (max-width: 829.98px) {
  .esg-image {
    width: 100%;
    background-position: center;
  }
}
@media (max-width: 575.98px) {
  .esg-image {
    height: 380px;
  }
}
.esg-image::after {
  content: "";
  position: absolute;
  top: -306px;
  right: -236px;
  min-width: 236px;
  min-height: 306px;
  background: #174363;
}
@media (max-width: 1359.98px) {
  .esg-image::after {
    min-width: 187px;
    min-height: 201px;
    top: -201px;
    right: -187px;
  }
}
@media (max-width: 829.98px) {
  .esg-image::after {
    display: none;
  }
}
.esg-image::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #0f3653 0%, #075d9c 100%);
  mix-blend-mode: screen;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.esg-year {
  font-family: "Playfair Display", serif;
  font-weight: 600;
  font-size: 38px;
  letter-spacing: 0.07em;
  color: #1f1f1f;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .esg-year {
    display: none;
  }
}

.commitment h2 {
  margin-bottom: 40px;
}
.commitment .section-content {
  max-width: 512px;
}
@media (max-width: 829.98px) {
  .commitment .section-content {
    max-width: 100%;
  }
}
.commitment-list {
  margin: 0;
  display: flex;
  gap: 52px;
  list-style-type: none;
  margin-top: 40px;
  padding: 0;
}
@media (max-width: 991.98px) {
  .commitment-list {
    gap: 60px;
  }
}
@media (max-width: 991.98px) {
  .commitment-list {
    gap: 36px;
  }
}
@media (max-width: 575.98px) {
  .commitment-list {
    gap: 12px;
    flex-direction: column;
  }
}
.commitment-list h4 {
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 0.01em;
  color: #1f1f1f;
  margin-bottom: 0;
}
@media (max-width: 1359.98px) {
  .commitment-list h4 {
    font-size: 24px;
  }
}
@media (max-width: 991.98px) {
  .commitment-list h4 {
    font-size: 18px;
  }
}
.commitment p {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.015em;
  color: #1f1f1f;
  margin-bottom: 0;
}
@media (max-width: 1359.98px) {
  .commitment p {
    font-size: 14px;
    line-height: 27px;
  }
}
@media (max-width: 399.98px) {
  .commitment p {
    font-size: 12px;
    line-height: 24px;
  }
}
.commitment p:not(:last-child) {
  margin-bottom: 12px;
}

/* --------------------------------------------------------------------------
3. Goal
 -------------------------------------------------------------------------- */
.goal {
  background-color: #fff;
}
.goal-title {
  font-family: "Playfair Display", serif;
  font-size: 42px;
  line-height: 68px;
  letter-spacing: 0.015em;
  color: #1f1f1f;
  font-weight: 400;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .goal-title {
    font-size: 45px;
    line-height: 73px;
  }
}
@media (max-width: 399.98px) {
  .goal-title {
    font-size: 28px;
    line-height: 45px;
    margin-bottom: 0;
  }
}
.goal-line {
  width: 100%;
  height: 7px;
  background-color: #e6e6e6;
}
.goal-subtitle {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  gap: 40px;
}
.goal-subtitle p {
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.015em;
  color: #1f1f1f;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .goal-subtitle p {
    font-size: 16px;
  }
}
@media (max-width: 575.98px) {
  .goal-subtitle {
    flex-direction: column-reverse;
    gap: 16px;
    padding-right: 0;
  }
}
.goal-grid {
  padding: 60px 0 160px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
@media (max-width: 991.98px) {
  .goal-grid {
    grid-template-columns: repeat(6, 1fr);
    padding: 30px 0 100px;
  }
}
@media (max-width: 829.98px) {
  .goal-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 575.98px) {
  .goal-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.goal-grid img {
  width: 100%;
}

/* --------------------------------------------------------------------------
4. Communities
 -------------------------------------------------------------------------- */
.communities {
  position: relative;
}
.communities::after {
  content: "";
  position: absolute;
  height: 7px;
  left: 30px;
  right: 30px;
  bottom: 80px;
}
@media (max-width: 1359.98px) {
  .communities::after {
    background-color: #e6e6e6;
  }
}
@media (max-width: 575.98px) {
  .communities::after {
    left: 15px;
    right: 15px;
  }
}
.communities .line {
  background-color: #f8f8f8;
  height: 4px;
  width: 100%;
  display: block;
  margin: -10px 0 80px;
  z-index: 1;
  position: relative;
}
.communities .section-subtitle {
  max-width: 536px;
}
.communities p {
  width: 386px;
}
@media (max-width: 829.98px) {
  .communities p {
    width: auto;
  }
}
.communities .timeline-grid-lines:nth-child(2)::after {
  position: absolute;
  content: "";
  background-image: url("../media/svg/building.svg");
  width: 80px;
  height: 80px;
  left: 47.2%;
  top: -6.5%;
}
@media (max-width: 1359.98px) {
  .communities .timeline-grid-lines:nth-child(2)::after {
    display: none;
  }
}
.communities .building-icon {
  position: relative;
}
.communities .building-icon::before {
  content: "";
  position: absolute;
  background-color: #dadada;
  height: 1px;
  top: 33.6%;
  left: 6px;
  right: 21px;
}
@media (max-width: 1639.98px) {
  .communities .building-icon::before {
    left: 24px;
  }
}
@media (max-width: 1399.98px) {
  .communities .building-icon::before {
    left: 12px;
    top: 26.2%;
  }
}
@media (max-width: 1359.98px) {
  .communities .building-icon::before {
    display: none;
  }
}

.milestone .section-title {
  padding-right: 10px;
}
.milestone .section-subtitle {
  max-width: 586px;
}
.milestone p {
  width: 440px;
}
@media (max-width: 829.98px) {
  .milestone p {
    width: auto;
  }
}
.milestone .timeline-grid-lines:nth-child(2)::after {
  position: absolute;
  content: "";
  background-image: url("../media/svg/home.svg");
  width: 80px;
  height: 80px;
  left: 47.2%;
  top: -6.5%;
}
@media (max-width: 1359.98px) {
  .milestone .timeline-grid-lines:nth-child(2)::after {
    display: none;
  }
}
.milestone .building-icon {
  position: relative;
}
.milestone .building-icon::before {
  content: "";
  position: absolute;
  background-color: #dadada;
  height: 1px;
  top: 36.2%;
  left: 6px;
  right: 21px;
}
@media (max-width: 1639.98px) {
  .milestone .building-icon::before {
    left: 24px;
  }
}
@media (max-width: 1399.98px) {
  .milestone .building-icon::before {
    left: 16px;
    top: 32.6%;
  }
}
@media (max-width: 1359.98px) {
  .milestone .building-icon::before {
    display: none;
  }
}
.milestone .building-icon::after {
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%3E%3Crect%20x%3D%226.36377%22%20width%3D%229%22%20height%3D%229%22%20transform%3D%22rotate(45%206.36377%200)%22%20fill%3D%22%230F3653%22%2F%3E%3C%2Fsvg%3E");
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 34.8%;
}
@media (max-width: 1399.98px) {
  .milestone .building-icon::after {
    top: 31.3%;
  }
}
@media (max-width: 1359.98px) {
  .milestone .building-icon::after {
    display: none;
  }
}
.milestone .grid-lines-center {
  padding-bottom: 60px;
}

.timeline {
  background-color: #fff;
  padding-bottom: 152px;
}
@media (max-width: 399.98px) {
  .timeline {
    padding-bottom: 90px;
  }
}
.timeline-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.015em;
  max-width: 468px;
  text-align: center;
  margin: auto;
  margin-bottom: 26px;
}
.timeline-bottom-center {
  transform: translateY(-32px);
}
@media (max-width: 1359.98px) {
  .timeline-bottom-center {
    transform: none;
  }
}
@media (max-width: 399.98px) {
  .timeline.communities {
    padding-bottom: 150px;
  }
}
.timeline .title-content {
  text-align: center;
  position: relative;
}
@media (max-width: 1359.98px) {
  .timeline .title-content {
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 0;
    padding-left: 72px;
    padding-bottom: 42px;
  }
  .timeline .title-content::after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #dadada;
    top: 5px;
    right: -24px;
    height: 100%;
  }
}
@media (max-width: 1359.98px) and (max-width: 1359.98px) {
  .timeline .title-content::after {
    right: auto;
    left: -42px;
  }
}
@media (max-width: 1359.98px) and (max-width: 575.98px) {
  .timeline .title-content::after {
    left: -30px;
  }
}
@media (max-width: 1359.98px) and (max-width: 399.98px) {
  .timeline .title-content::after {
    left: -20px;
  }
}
@media (max-width: 575.98px) {
  .timeline .title-content {
    padding-left: 40px;
  }
}
@media (max-width: 399.98px) {
  .timeline .title-content {
    padding-left: 30px;
  }
}
.timeline .title-content:not(.title-content-center)::before {
  content: "";
  position: absolute;
  background-color: #dadada;
  top: -33%;
  height: 33%;
  left: 49.8%;
  width: 0.5px;
  transform: translateX(-50%);
}
@media (max-width: 1399.98px) {
  .timeline .title-content:not(.title-content-center)::before {
    top: -40%;
    height: 40%;
  }
}
@media (max-width: 1359.98px) {
  .timeline .title-content:not(.title-content-center)::before {
    display: none;
  }
}
.timeline .title-content-center {
  transform: translateY(110%);
}
@media (max-width: 1359.98px) {
  .timeline .title-content-center {
    transform: translateY(0);
  }
}
.timeline h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.015em;
  max-width: 472px;
}
@media (max-width: 399.98px) {
  .timeline h6 {
    font-size: 18px;
    line-height: 28px;
  }
}
.timeline p {
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.015em;
  margin: auto;
  margin-top: 24px;
}
@media (max-width: 1359.98px) {
  .timeline p {
    margin: 0;
    text-align: left;
  }
}
@media (max-width: 575.98px) {
  .timeline p {
    font-size: 12px;
    line-height: 24px;
  }
}
.timeline-title-content {
  position: relative;
}
.timeline-title-content::after {
  position: absolute;
  content: "";
  height: 6px;
  left: 12px;
  right: 12px;
  bottom: -40px;
}
@media (max-width: 1359.98px) {
  .timeline-title-content::after {
    background-color: #e6e6e6;
  }
}
@media (max-width: 399.98px) {
  .timeline-title-content::after {
    bottom: -32px;
  }
}
.timeline-content {
  display: flex;
  align-items: flex-start;
}
.timeline-grid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (max-width: 1359.98px) {
  .timeline-grid {
    padding-left: 72px;
  }
}
@media (max-width: 575.98px) {
  .timeline-grid {
    padding-left: 40px;
  }
}
@media (max-width: 399.98px) {
  .timeline-grid {
    padding-left: 30px;
  }
}
.timeline-grid h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #1f1f1f;
  margin-bottom: 10px;
}
.timeline-grid i {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #7c7c7c;
  display: block;
}
.timeline .grid-lines-left {
  position: relative;
}
@media (min-width: 1360px) {
  .timeline .grid-lines-left.grid-lines-center h3::before {
    display: none;
  }
}
.timeline .grid-lines-left:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #dadada;
  top: 5px;
  right: -24px;
  height: 100%;
}
@media (max-width: 1359.98px) {
  .timeline .grid-lines-left:not(:last-child)::after {
    right: auto;
    left: -114px;
  }
}
@media (max-width: 575.98px) {
  .timeline .grid-lines-left:not(:last-child)::after {
    left: -70px;
  }
}
@media (max-width: 399.98px) {
  .timeline .grid-lines-left:not(:last-child)::after {
    left: -50px;
  }
}
@media (max-width: 1359.98px) {
  .timeline .grid-lines-left:last-child {
    padding-bottom: 60px;
  }
  .timeline .grid-lines-left:last-child::after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #dadada;
    top: 5px;
    right: -24px;
    height: 100%;
  }
}
@media (max-width: 1359.98px) and (max-width: 1359.98px) {
  .timeline .grid-lines-left:last-child::after {
    right: auto;
    left: -114px;
  }
}
@media (max-width: 1359.98px) and (max-width: 575.98px) {
  .timeline .grid-lines-left:last-child::after {
    left: -70px;
  }
}
@media (max-width: 1359.98px) and (max-width: 399.98px) {
  .timeline .grid-lines-left:last-child::after {
    left: -50px;
  }
}
@media (max-width: 399.98px) {
  .timeline .grid-lines-left:last-child {
    padding-bottom: 45px;
  }
}
.timeline .grid-lines-left h3 {
  position: relative;
}
.timeline .grid-lines-left h3.two-lines::after {
  top: 28%;
}
.timeline .grid-lines-left h3::before {
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%3E%3Crect%20x%3D%226.36377%22%20width%3D%229%22%20height%3D%229%22%20transform%3D%22rotate(45%206.36377%200)%22%20fill%3D%22%230F3653%22%2F%3E%3C%2Fsvg%3E");
  z-index: 1;
  right: -30px;
  top: 0;
}
@media (max-width: 1359.98px) {
  .timeline .grid-lines-left h3::before {
    left: -120px;
    right: auto;
  }
}
@media (max-width: 575.98px) {
  .timeline .grid-lines-left h3::before {
    left: -76px;
  }
}
@media (max-width: 399.98px) {
  .timeline .grid-lines-left h3::before {
    left: -56px;
  }
}
.timeline .grid-lines-right {
  max-width: 450px;
  position: relative;
}
.timeline .grid-lines-right:not(:last-child)::before {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #dadada;
  top: 5px;
  /* bottom: 0; */
  left: -34px;
  height: 100%;
}
@media (max-width: 1359.98px) {
  .timeline .grid-lines-right:not(:last-child)::before {
    right: auto;
    left: -114px;
  }
}
@media (max-width: 575.98px) {
  .timeline .grid-lines-right:not(:last-child)::before {
    left: -70px;
  }
}
@media (max-width: 399.98px) {
  .timeline .grid-lines-right:not(:last-child)::before {
    left: -50px;
  }
}
.timeline .grid-lines-right h3 {
  position: relative;
}
.timeline .grid-lines-right h3::before {
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%3E%3Crect%20x%3D%226.36377%22%20width%3D%229%22%20height%3D%229%22%20transform%3D%22rotate(45%206.36377%200)%22%20fill%3D%22%230F3653%22%2F%3E%3C%2Fsvg%3E");
  z-index: 1;
  left: -40px;
  top: 0;
}
@media (max-width: 1359.98px) {
  .timeline .grid-lines-right h3::before {
    left: -120px;
    right: auto;
  }
}
@media (max-width: 575.98px) {
  .timeline .grid-lines-right h3::before {
    left: -76px;
  }
}
@media (max-width: 399.98px) {
  .timeline .grid-lines-right h3::before {
    left: -56px;
  }
}
.timeline .grid-lines-center {
  max-width: 450px;
}
@media (max-width: 1359.98px) {
  .timeline .grid-lines-center {
    margin-top: 0;
    text-align: left;
  }
}
.timeline .grid-lines-center .timeline-grid-item {
  justify-content: center;
}
@media (max-width: 1359.98px) {
  .timeline .grid-lines-center .timeline-grid-item {
    justify-content: flex-start;
  }
}
.timeline .grid-lines-item {
  width: 325px;
}
@media (max-width: 1399.98px) {
  .timeline .grid-lines-item {
    width: auto;
  }
}
.timeline .grid-lines-item:not(:last-child) {
  padding-bottom: 60px;
}
@media (max-width: 399.98px) {
  .timeline .grid-lines-item:not(:last-child) {
    padding-bottom: 45px;
  }
}
.timeline-grid-item {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 0;
  flex-wrap: wrap;
}
@media (max-width: 399.98px) {
  .timeline-grid-item {
    margin-top: 16px;
  }
}
.timeline-grid-item img {
  width: 100px;
}
@media (max-width: 575.98px) {
  .timeline-grid-item img {
    width: 90px;
  }
}

/* --------------------------------------------------------------------------
5. Contact
 -------------------------------------------------------------------------- */
.contact-hero {
  height: 670px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 80px;
}
@media (max-width: 1359.98px) {
  .contact-hero {
    align-items: flex-start;
  }
}
@media (max-width: 829.98px) {
  .contact-hero {
    height: 475px;
  }
}
.contact-hero-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 829.98px) {
  .contact-hero-inner-desktop {
    display: none;
  }
}
.contact-hero-bg {
  height: 1230px;
  min-width: 42%;
  background-image: url("../media/images/contact-us.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 1;
  position: relative;
  padding: 74px 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-hero-content {
  position: relative;
  color: #fff;
  z-index: 1;
}
.contact-hero-content-description {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  gap: 100px;
}
@media (max-width: 1639.98px) {
  .contact-hero-content-description {
    gap: 50px;
  }
}
@media (max-width: 1399.98px) {
  .contact-hero-content-description {
    gap: 100px;
  }
}
@media (max-width: 1359.98px) {
  .contact-hero-content-description {
    background-color: #fff;
    color: #383838;
    padding: 60px 100px;
    position: relative;
    flex-direction: column;
    margin-top: -205px;
  }
  .contact-hero-content-description::after {
    content: "";
    position: absolute;
    height: 7px;
    left: 100px;
    right: 100px;
    top: 46%;
    background-color: #e6e6e6;
  }
}
@media (max-width: 1359.98px) and (max-width: 829.98px) {
  .contact-hero-content-description::after {
    left: 46px;
    right: 46px;
    top: 32%;
  }
}
@media (max-width: 1359.98px) and (max-width: 575.98px) {
  .contact-hero-content-description::after {
    left: 0;
    right: 0;
    top: 40%;
  }
}
@media (max-width: 1359.98px) and (max-width: 399.98px) {
  .contact-hero-content-description::after {
    top: 44%;
  }
}
@media (max-width: 829.98px) {
  .contact-hero-content-description {
    flex-direction: column;
    padding: 56px 35px;
    margin-top: -186px;
  }
}
@media (max-width: 575.98px) {
  .contact-hero-content-description {
    padding: 0;
    gap: 25px;
  }
}
.contact-hero-content h2 {
  font-family: "Playfair Display", serif;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: 0.005em;
  font-weight: 400;
  margin-bottom: 60px;
  text-align: center;
}
@media (max-width: 1359.98px) {
  .contact-hero-content h2 {
    margin-bottom: 0;
    margin-top: 235px;
    text-align: center;
  }
}
@media (max-width: 829.98px) {
  .contact-hero-content h2 {
    margin-top: 130px;
  }
}
@media (max-width: 575.98px) {
  .contact-hero-content h2 {
    margin-top: 180px;
    font-size: 38px;
    line-height: 46px;
  }
}
.contact-hero-content h6 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.015em;
  margin-bottom: 20px;
}
@media (max-width: 575.98px) {
  .contact-hero-content h6 {
    font-size: 18px;
    line-height: 35px;
  }
}
.contact-hero-content h6 + p {
  line-height: 35px;
  letter-spacing: 0.055em;
  max-width: 340px;
}
@media (max-width: 829.98px) {
  .contact-hero-content h6 + p {
    margin: auto;
  }
}
@media (max-width: 575.98px) {
  .contact-hero-content h6 + p {
    line-height: 24px;
    font-size: 12px;
  }
}
.contact-hero-content .investor-relations {
  padding: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin-bottom: 0;
  flex-direction: column;
}
@media (max-width: 1359.98px) {
  .contact-hero-content .investor-relations {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (max-width: 829.98px) {
  .contact-hero-content .investor-relations {
    flex-direction: column;
    gap: 32px;
  }
}
.contact-hero-content .investor-relations div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 575.98px) {
  .contact-hero-content .investor-relations div {
    gap: 0;
  }
}
.contact-hero-content .investor-relations div p,
.contact-hero-content .investor-relations div span {
  font-size: 16px;
  letter-spacing: 0.015em;
  margin-bottom: 0;
  font-weight: normal;
}
@media (max-width: 575.98px) {
  .contact-hero-content .investor-relations div p,
.contact-hero-content .investor-relations div span {
    font-size: 12px;
  }
}
.contact-hero-content .investor-relations div p {
  font-weight: 600;
}
.contact-hero-content .investor-relations div span {
  display: block;
}

@media (max-width: 829.98px) {
  .contact-address {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .contact-address {
    padding: 56px 35px;
  }
}

.contact-detail {
  display: flex;
  gap: 135px;
}
.contact-detail-title {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.015em;
  color: #fff;
  position: relative;
  margin-bottom: 0;
}
.contact-detail-title::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 1px;
  top: 10px;
  right: -110px;
  background-color: #fff;
}
@media (max-width: 1359.98px) {
  .contact-detail-title::after {
    display: none;
  }
}
@media (max-width: 1359.98px) {
  .contact-detail-title {
    color: #383838;
  }
}
@media (max-width: 1359.98px) {
  .contact-detail {
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 829.98px) {
  .contact-detail {
    text-align: center;
  }
}

/* --------------------------------------------------------------------------
6. Investor
 -------------------------------------------------------------------------- */
.investor {
  margin: 60px 0 150px;
}
@media (max-width: 1359.98px) {
  .investor {
    padding: 0 100px;
    margin: 0 0 100px;
  }
}
@media (max-width: 829.98px) {
  .investor {
    margin-bottom: 0;
    padding: 56px 46px;
  }
}
@media (max-width: 575.98px) {
  .investor {
    padding: 56px 46px;
  }
}
.investor-title {
  font-size: 45px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.015em;
  color: #212f3b;
  font-family: "Playfair Display", serif;
  margin-bottom: 56px;
  font-weight: 400;
}
@media (max-width: 1359.98px) {
  .investor-title {
    font-size: 42px;
    line-height: 82px;
    text-align: left;
  }
}
@media (max-width: 829.98px) {
  .investor-title {
    font-size: 28px;
    line-height: 55px;
    margin-bottom: 32px;
    text-align: center;
  }
}
.investor-item {
  text-align: center;
}
@media (max-width: 1359.98px) {
  .investor-item {
    text-align: left;
  }
}
@media (max-width: 829.98px) {
  .investor-item {
    margin-bottom: 32px;
    text-align: center;
  }
}
.investor-item p {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.015em;
  color: #db812d;
  margin-bottom: 22px;
}
@media (max-width: 991.98px) {
  .investor-item p {
    font-size: 16px;
    line-height: 35px;
  }
}
.investor-item img {
  display: block;
  margin: auto;
}
@media (max-width: 1359.98px) {
  .investor-item img {
    margin-left: 0;
  }
}
@media (max-width: 829.98px) {
  .investor-item img {
    margin-left: auto;
  }
}
@media (max-width: 575.98px) {
  .investor-item img {
    width: 70px;
  }
}
.investor-item a {
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.015em;
  color: #212f3b;
  margin-top: 10px;
  display: block;
}
@media (max-width: 1359.98px) {
  .investor-item a {
    text-align: left;
  }
}
@media (max-width: 991.98px) {
  .investor-item a {
    font-size: 16px;
    line-height: 35px;
  }
}
@media (max-width: 829.98px) {
  .investor-item a {
    text-align: center;
  }
}
.investor-item span {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.015em;
  color: #212f3b;
}
@media (max-width: 991.98px) {
  .investor-item span {
    font-size: 16px;
    line-height: 35px;
  }
}

/* --------------------------------------------------------------------------
7. Universal Hero
 -------------------------------------------------------------------------- */
.universal-hero {
  height: 670px;
  display: flex;
  text-align: center;
  align-items: flex-start;
}
@media (max-width: 991.98px) {
  .universal-hero {
    height: 475px;
  }
}
.universal-hero-content {
  position: relative;
  color: #fff;
  z-index: 1;
}
.universal-hero-content h1 {
  font-family: "Playfair Display", serif;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: 0.005em;
  font-weight: 400;
  margin-top: 225px;
}
@media (max-width: 1359.98px) {
  .universal-hero-content h1 {
    font-size: 68px;
    line-height: 84px;
  }
}
@media (max-width: 991.98px) {
  .universal-hero-content h1 {
    font-size: 42px;
    line-height: 52px;
    margin-top: 186px;
  }
}
@media (max-width: 575.98px) {
  .universal-hero-content h1 {
    font-size: 38px;
    line-height: 46px;
  }
}

/* --------------------------------------------------------------------------
4. Leadership
 -------------------------------------------------------------------------- */
.one-page-explanation .content-togglable {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 37px;
}
@media (max-width: 991.98px) {
  .one-page-explanation .content-togglable {
    gap: 27px;
  }
}
.one-page-explanation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 72px;
}
@media (max-width: 991.98px) {
  .one-page-explanation-wrapper {
    gap: 90px;
  }
}
@media (max-width: 575.98px) {
  .one-page-explanation-wrapper {
    gap: 60px;
  }
}
.one-page-explanation-item {
  position: relative;
}
.one-page-explanation-item::before {
  position: absolute;
  content: "";
  background-color: #f8f8f8;
  height: 4px;
  left: 0;
  right: 0;
  top: -46px;
}
@media (max-width: 991.98px) {
  .one-page-explanation-item::before {
    top: -35px;
  }
}
@media (max-width: 575.98px) {
  .one-page-explanation-item::before {
    top: -26px;
  }
}
.one-page-explanation-item .btn-circle-toggle {
  margin-top: 10px;
}
.one-page-explanation-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 130px;
}
@media (max-width: 1359.98px) {
  .one-page-explanation-content {
    gap: 85px;
  }
}
.one-page-explanation-content-togglable {
  column-gap: 45px;
}
.one-page-explanation-content-togglable p {
  font-size: 14px;
  line-height: 27px;
  text-align: left;
  letter-spacing: 0.015em;
}
@media (max-width: 991.98px) {
  .one-page-explanation-content-togglable p {
    font-size: 12px;
    line-height: 24px;
  }
}
.one-page-explanation-name {
  font-family: "Playfair Display", serif;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.005em;
}
.one-page-explanation-position p {
  font-weight: 500;
  letter-spacing: 0.13em;
  margin-bottom: 0;
  flex-shrink: 0;
  color: #db812d;
  font-size: 10px;
  line-height: 20px;
}
.one-page-explanation-general {
  font-family: "Playfair Display", serif;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 0.015em;
  margin-bottom: 12px;
  font-weight: 400;
}
.one-page-explanation-general + button {
  margin-right: 16px;
}
@media (max-width: 1359.98px) {
  .one-page-explanation-general {
    font-size: 22px;
    line-height: 38px;
  }
}
@media (max-width: 829.98px) {
  .one-page-explanation-general {
    font-size: 16px;
    line-height: 28px;
    margin-top: 12px;
  }
}
@media (max-width: 575.98px) {
  .one-page-explanation-general {
    max-width: 330px;
  }
}
@media (max-width: 399.98px) {
  .one-page-explanation-general {
    font-size: 12px;
    line-height: 21px;
  }
}

/* Not found page */
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
.not-found h2 {
  font-family: "Playfair Display", serif;
}
.not-found ul {
  padding: 0;
  margin-top: 24px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.not-found ul a {
  text-transform: capitalize;
  position: relative;
}
.not-found ul a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 1px;
  width: 0;
  background-color: black;
  transition: 300ms;
}
.not-found ul a:hover::before {
  transition: 300ms;
  width: 100%;
}

/* --------------------------------------------------------------------------
4. Option Selection Slider
 -------------------------------------------------------------------------- */
.bg-warehouse {
  position: relative;
}
.bg-warehouse::after {
  content: "";
  position: fixed;
  inset: 0;
  background-image: url("../media/images/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}

.bg-blue-overlay {
  position: relative;
}
.bg-blue-overlay::after {
  content: "";
  position: fixed;
  inset: 0;
  background: linear-gradient(180deg, rgba(15, 54, 83, 0.75) 0%, rgba(15, 54, 83, 0.9) 100%);
  z-index: 1;
}